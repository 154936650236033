import { ModelWithUsers } from './modelWithUsers';
import { Document } from './document';
import { Swimlane } from './swimlane';
import { bySort } from 'app/common/helpers';

export class Sphere extends ModelWithUsers {

  title = '';
  code = '';

  dreams = '';
  goals = '';

  documents: Document[] = [];

  // Swimlanes for Circles.
  swimlanes: Swimlane[] = null;
  num_circles: number = null;
  all_circles_loaded = false; // Set to true when Circles I'm not in are loaded.

  swimlane_id = 0; // in User's view
  sort_in_swimlane = 0;

  constructor(object?: Partial<Sphere>) {
    super(object);
    this.fill(object);

    if (object && typeof object === 'object') {
      // Make the Document list into real Document objects.
      if (this.documents.length) {
        this.documents = Document.parseObjects(this.documents);
      }
      // Make the new Swimlane list into real Swimlane objects.
      if (this.swimlanes?.length) {
        this.swimlanes = Swimlane.parseObjects(this.swimlanes);
        this.swimlanes.sort(bySort);
      } else {
        this.swimlanes = null;
      }
    }
  }

  get trimmedDescription() {
    return this.dreams?.replace('<p></p>', '').replace('<p><br></p>', '') || '';
  }

}
