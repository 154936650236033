import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from 'app/services';
import { Notification } from 'app/models';

@Component({
  selector: 'flxion-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  @Input() notificPanel;
  private stopper = new Subject<void>();

  constructor(
    private router: Router,
    public ns: NotificationService,
    public snack: MatSnackBar
  ) {}

  ngOnInit() {
    // Close notification panel after navigating to a page.
    this.router.events.pipe(takeUntil(this.stopper)).subscribe((routeChange) => {
      if (routeChange instanceof NavigationEnd) {
        this.notificPanel.close();
      }
    });
  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

  markAsRead(n: Notification) {
    return this.ns.markAsRead(n);
  }

  markAllAsRead() {
    return this.ns.markAllAsRead();
  }

  icon(n: Notification): { icon: string, fontSet?: string } {
    switch (n.type) {
      case 'email-verif' : return {icon: 'email', fontSet: 'material-icons-outlined'};
      case 'meeting' : return {icon: 'date_range'};
      case 'task' : return {icon: 'format_list_bulleted'};
      default: return {icon: ''};
    }
  }

  clickAction(n: Notification) {
    this.markAsRead(n);
    if (n.type === 'email-verif') {
      this.notificPanel.close();
      this.ns.resendEmailVerification();
    }
  }

  time(n: Notification) {
    return formatDate(n.created_at * 1000, 'd MMMM, HH:mm', 'en-US');
  }

}
