import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params } from '@angular/router';

interface RoutePart {
  title: string,
  breadcrumb: string,
  params?: Params,
  url: string,
  urlSegments: any[]
}

@Injectable({ providedIn: 'root' })
export class NavigationService {

  hardwareNavClicked = false;
  preventDeactivate = false;

  constructor() {}

  setHardwareNavClicked() {
    this.hardwareNavClicked = true;
    // Reset it soon. But not too soon.
    setTimeout(() => {
      this.hardwareNavClicked = false;
    }, 300);
  }

  // Not used yet in Flxion project.
  setPreventDeactivate() {
    this.preventDeactivate = true;
    // Reset it soon. But not too soon.
    setTimeout(() => {
      this.preventDeactivate = false;
    }, 300);
  }

  generateRouteParts(snapshot: ActivatedRouteSnapshot): RoutePart[] {
    let routeParts = <RoutePart[]>[];
    if (snapshot) {
      if (snapshot.firstChild) {
        routeParts = routeParts.concat(this.generateRouteParts(snapshot.firstChild));
      }
      if (snapshot.data['title'] && snapshot.url.length) {
        routeParts.push({
          title: snapshot.data['title'],
          breadcrumb: snapshot.data['breadcrumb'],
          url: snapshot.url[0].path,
          urlSegments: snapshot.url,
          params: snapshot.params
        });
      }
    }
    return routeParts;
  }

}
