import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { auditTime, map, tap } from 'rxjs/operators';
import { BehaviorSubject, mergeMap, Observable, of, skipWhile, take, throwError } from 'rxjs';
import { Store, createState } from '@ngneat/elf';
import {
  addEntities,
  deleteAllEntities,
  deleteEntities,
  getActiveId,
  getAllEntities,
  getEntity,
  hasEntity,
  updateEntities,
  selectActiveEntity,
  selectAllEntities,
  selectEntity,
  setActiveId,
  setEntities,
  withActiveId,
  withEntities,
} from '@ngneat/elf-entities';

import { SpheresRepository } from './spheres.repository';
import { SwimlanesRepository } from './swimlanes.repository';
import { Circle, User } from 'app/models';
import { authUser } from 'app/common/helpers';

@Injectable({ providedIn: 'root' })
export class CirclesRepository extends SwimlanesRepository {

  // To make this service available to Model classes.
  static instance: CirclesRepository;

  // For SwimlaneRepository.
  endpointURL = 'circles';

  loaded = new BehaviorSubject(false);

  activeCircle$: Observable<Circle>;
  circles$: Observable<Circle[]>;

  private store = new Store({
    name: 'circles',
    ...createState(
        withEntities<Circle>(),
        withActiveId()
    )
  });

  constructor(
    protected http: HttpClient,
    private spheresRepo: SpheresRepository,
  ) {
    super();
    CirclesRepository.instance = this;

    this.activeCircle$ = this.store.pipe(selectActiveEntity(), map(circle => circle ? new Circle(circle) : null));
    this.circles$ = this.store.pipe(selectAllEntities(), auditTime(50), map((circles: Circle[]) => Circle.parseObjects(circles)));
  }

  get whenLoaded() {
    return this.loaded.pipe(skipWhile(val => !val), take(1));
  }
  get circles(): Circle[] {
    return Circle.parseObjects(this.store.query(getAllEntities()));
  }
  get activeCircle() {
    return this.get(this.getActiveId());
  }

  loadAll() {
    return this.http.get<{ data: Circle[] }>(`/api/circles`)
      .pipe(
        tap(x => {
          const virtualOne = this.get(-1);
          this.checkSphereLessCircles(x.data, !!virtualOne);
          this.store.update(setEntities(x.data));
          if (virtualOne) {
            this.store.update(addEntities(virtualOne));
          }
          this.loaded.next(true);
          this.spheresRepo.whenLoaded.subscribe(() => this.countCirclesPerSphere());
        }),
      );
  }

  clearAll() {
    this.store.update(deleteAllEntities());
    this.spheresRepo.spheres.filter(s => s.all_circles_loaded).forEach(
      s => this.spheresRepo.updateLocal(s.id, {all_circles_loaded: false})
    )
  }

  reloadAll() {
    this.clearAll();
    return this.loadAll();
  }

  select(id: number) {
    return this.store.pipe(selectEntity(id), map(circle => new Circle(circle)));
  }
  fetch(id: number) {
    return this.http.get<{ data: Circle }>(`/api/circles/${id}`)
      .pipe(
        tap(x => {
          if (this.has(id)) {
            this.updateLocal(id, x.data);
          } else {
            this.store.update(addEntities(x.data));
          }
        })
      );
  }
  get(id: number) {
    const object = this.store.query(getEntity(id));
    return object?.id ? new Circle(object) : null;
  }
  has(id: number) {
    return this.store.query(hasEntity(id));
  }

  add(circle: Circle) {
    return this.http.post<any>(`/api/circles`, circle)
      .pipe(
        map(x => new Circle(x.data)),
        tap(c => this.store.update(addEntities(c)))
      );
  }

  update(id: number, data: Partial<Circle>, saveToAPI = true) {
    // Update in repo; may be updated again after API save.
    this.store.update(updateEntities(id, data));
    if (saveToAPI) {
      return this.http.put<{ data: Partial<Circle> }>(`/api/circles/${id}`, data)
        .pipe(
          map(x => {
            this.store.update(updateEntities(id, x.data));
            return x.data;
          }),
        );
    } else {
      return of(this.get(id));
    }
  }
  updateLocal(id: number, data: Partial<Circle>) {
    return this.update(id, data, false);
  }

  updateSphereId(id: number, sphere_id: number) {
    return this.http.put<any>(`/api/circles/${id}/sphere`, { sphere_id })
      .pipe(
        tap(() => {
          this.store.update(updateEntities(id, { sphere_id }));
          this.countCirclesPerSphere();
        })
      );
  }

  // Set the SwimlaneForCircles on this Circle.
  updateSwimlaneId(id: number, sphere_id: number, swimlane_id: number) {
    return this.http.put<any>(`/api/circles/${id}/swimlane`, { sphere_id, swimlane_id })
      .pipe(
        tap(() => this.store.update(updateEntities(id, { swimlane_id })))
      );
  }

  // Set the fixed MySwimlane on this Circle.
  updateMySwimlaneId(id: number, my_swimlane_id: number) {
    return this.http.put<any>(`/api/circles/${id}/my-swimlane`, { my_swimlane_id })
      .pipe(
        tap(() => this.store.update(updateEntities(id, { my_swimlane_id })))
      );
  }

  updateUsers(id: number, users: User[]) {
    const data = users.map(u => {
      const fields: Partial<User> = {id: u.id, label: u.label, access_level: u.access_level};
      if (!u.id) {
        fields.email = u.email;
      }
      return fields;
    });
    return this.http.put<any>(`/api/circles/${id}/users`, { users: data })
      .pipe(
        map(x => User.parseObjects(x.data)),
        tap(list => this.store.update(updateEntities(id, { users: list })))
      );
  }

  delete(id: number) {
    if (!id) {
      return throwError(() => new Error('Circle id is missing.'));
    }
    return this.http.delete(`/api/circles/${id}`).pipe(
      tap(() => this.store.update(deleteEntities(id)))
    );
  }

  leave(id: number) {
    if (!id) {
      return throwError(() => new Error('Circle id is missing.'));
    }
    return this.http.post<any>(`/api/circles/${id}/leave`, {}).pipe(
      // We currently reload the whole db to avoid having to figure out
      // whether to leave it as readonly or remove it entirely.
      mergeMap(() => this.reloadAll())
    );
  }

  rejoin(id: number) {
    if (!id) {
      return throwError(() => new Error('Circle id is missing.'));
    }
    return this.http.post<any>(`/api/circles/${id}/rejoin`, {}).pipe(
      mergeMap(() => this.reloadAll())
    );
  }

  setActiveId(id: number) {
    if (this.getActiveId() === id) {
      return;
    }

    const circle = this.get(id);
    if (circle) {
      if (!circle.includes_me) {
        console.warn('You cannot enter this circle because you are not a participant.');
        return;
      }
      SpheresRepository.instance.setActiveId(circle.sphere_id);
      localStorage.setItem('circleId', '' + id);
    } else {
      id = null;
      localStorage.removeItem('circleId');
    }
    this.store.update(setActiveId(id));
  }

  getActiveId(): number {
    return this.store.query(getActiveId) || 0;
  }

  enableDefault() {
    if (this.store.query(hasEntity(-1))) {
      return;
    }

    this.spheresRepo.enableDefault();

    this.store.update(addEntities(new Circle({
      id: -1,
      sphere_id: -1,
      title: 'Other meetings',
      mission: 'This circle holds all meetings that are not yet assigned to any of your other circles.',
      users: [authUser()],
      all_meetings_loaded: true,
    })));
  }

  disableDefault() {
    this.store.update(deleteEntities(-1));
  }

  // Called after Spheres and Circles are loaded; or after a Circle may have switched Spheres.
  countCirclesPerSphere() {
    this.spheresRepo.spheres.forEach(sphere => {
      const num_circles = this.circles.filter(circle => circle.sphere_id === sphere.id).length;
      if (num_circles !== sphere.num_circles) {
        this.spheresRepo.updateLocal(sphere.id, { num_circles });
      }
    });
  }

  loadAllForSphereId(sphereId: number): boolean {
    if (sphereId <= 0) {
      return;
    }

    // Ensure the sphere is known.
    if (!this.spheresRepo.get(sphereId)?.id) {
      console.warn('Cannot load circles for sphere: sphere not found.', sphereId);
      return false;
    }

    this.http.get<any>(`/api/spheres/${sphereId}/circles`)
      .pipe(
        map(x => x.data),
        tap(circles => {
          circles.forEach(data => {
            if (this.store.query(hasEntity(data.id))) {
              this.updateLocal(data.id, data);
            } else {
              this.store.update(addEntities(new Circle(data)));
            }
          });
          this.spheresRepo.updateLocal(sphereId, { all_circles_loaded: true });
        }),
      ).subscribe();
    return true;
  }

  private checkSphereLessCircles(list: Circle[], hasVirtualCircle: boolean) {
    if (hasVirtualCircle) {
      this.spheresRepo.enableDefault();
      return;
    }
    if (!list) {
      list = this.circles;
    }
    // Assign sphere-less circles to a virtual 'default' sphere.
    const circlesWithoutSphere = list.filter(c => !c.sphere_id || c.sphere_id === -1);
    if (circlesWithoutSphere.length) {
      this.spheresRepo.enableDefault();
      circlesWithoutSphere.forEach(circle => {
        this.updateLocal(circle.id, { sphere_id: -1 });
      });
    } else {
      this.spheresRepo.disableDefault();
    }
  }

}
