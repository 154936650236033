import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

import { Document, Meeting, User, Task } from 'app/models';
import { CirclesRepository, MeetingsRepository, SpheresRepository } from 'app/repositories';
import { UserService } from './user.service';
import { DocumentService } from './document.service';
import { MeetingDetailsComponent } from 'app/modules/meetings/details/meeting-details.component';
import { AppLoaderService } from 'app/common/app-loader/app-loader.service';

@Injectable({
  providedIn: 'root'
})
export class MeetingService extends DocumentService {

  justUpdated = false;
  activateComponent: MeetingDetailsComponent;
  newStartData; // temp storage for Quick Call Note
  storedToken: string;

  protected endpointName = 'meetings';

  private cachedSearch: { key: string, result: number[]};

  constructor(
    protected http: HttpClient,
    private router: Router,
    private circleRepo: CirclesRepository,
    private sphereRepo: SpheresRepository,
    private userService: UserService,
    private repo: MeetingsRepository,
    private loader: AppLoaderService,
  ) {
    super();
  }

  /**
   * Group a plain list of Meetings by their Stacks.
   * Also make sure no more than one unlocked future Meeting is on top.
   */
  groupByStack(meetings: Meeting[]): Meeting[] {
    const newList: Meeting[] = [];
    const stacks = new Map<number, Meeting[]>();
    meetings.forEach(object => {
      const meeting = new Meeting(object);
      const stack = stacks.get(meeting.stack_id);
      if (stack) {
        stack.push(meeting);
      } else {
        stacks.set(meeting.stack_id, [meeting]);
      }
    });

    if (stacks.size) {
      const now = Date.now() / 1000;
      stacks.forEach((stack: Meeting[]) => {
        // Make sure the most recent one is in the front.
        stack.sort(this.meetingDescSorter);
        // If there are multiple unlocked meetings on top, select the earliest one, discard the rest.
        let topMeeting: Meeting;
        do {
          topMeeting = stack.shift();
          // Meetings that recently ended (less than 4 hours ago) are kept on the top, without a future one covering it.
        } while (stack.length && !stack[0].locked && (stack[0].date_time + (stack[0].duration || 0) * 60 + 3600*4) > now);

        // stack now contains only the older Meetings from the list.
        if (stack.length) {
          topMeeting.stack = stack;
          // Sum the TaskCompletions.
          if (topMeeting.tasks_completion) {
            stack.forEach(meeting => {
              if (meeting.tasks_completion) {
                topMeeting.tasks_completion.done += meeting.tasks_completion.done;
                topMeeting.tasks_completion.todo_danger += meeting.tasks_completion.todo_danger;
                topMeeting.tasks_completion.todo_safe += meeting.tasks_completion.todo_safe;
                topMeeting.tasks_completion.todo_warning += meeting.tasks_completion.todo_warning;
                topMeeting.tasks_completion.total += meeting.tasks_completion.total;
              }
            });
          }
        }
        newList.push(topMeeting);
      });
      newList.sort(this.meetingDescSorter);
    }

    return newList;
  }

  meetingDescSorter = (a, b) => b.date_time > a.date_time ? 1 : (b.date_time < a.date_time ? -1 : (b.id > a.id ? 1 : -1));
  meetingAscSorter = (a, b) => b.date_time < a.date_time ? 1 : (b.date_time > a.date_time ? -1 : (b.id < a.id ? 1 : -1));

  updateSwimlaneId(meeting: Meeting, circle_id: number, swimlane_id: number) {
    return this.http.put<any>(`/api/meetings/${meeting.id}/swimlane`, { circle_id, swimlane_id })
      .pipe(
        tap(() => this.repo.updateLocal(meeting.id, {
          circles: meeting.circles.map(
            c => c.id === circle_id ? Object.assign(c, { swimlane_id }) : c
          )
        }))
      );
  }

  delete(id: number) {
    this.loader.open('Deleting...');
    return this.http.delete(`/api/meetings/${id}`)
      .pipe(
        tap(() => this.repo.delete(id)),
        finalize(() => this.loader.close()),
      );
  }

  getUsers(id: number) {
    return this.http.get<any>(`/api/meetings/${id}/users`)
      .pipe(
        map(x => User.parseObjects(x.data))
      );
  }

  updateUsers(id: number, users: User[]) {
    return this.http.put<any>(`/api/meetings/${id}/users`, { users })
      .pipe(
        map(x => User.parseObjects(x.data)),
        tap(u => this.repo.updateLocal(id, { users: u }))
      );
  }

  // Locks the meeting, and returns the lock timestamp.
  lock(id: number) {
    return this.http.post<string>(`/api/meetings/${id}/lock`, {})
      .pipe(
        map(x => +x),
        tap(locked => this.repo.updateLocal(id, { status: Meeting.STATUS_LOCKED, locked }))
      );
  }

  unlock(id: number) {
    return this.http.post(`/api/meetings/${id}/unlock`, {})
      .pipe(
        tap(() => this.repo.updateLocal(id, { status: Meeting.STATUS_FINISHED, locked: 0 }))
      );
  }

  remindToFinalize(id: number) {
    return this.http.post(`/api/meetings/${id}/remind-to-finalize`, {});
  }

  sortTasks(id: number, tasks: Task[]) {
    const list = tasks.map(task => task.id);
    return this.http.put<any>(`/api/meetings/${id}/sort-tasks`, { ids: list });
  }

  getFiles(id: number) {
    return this.http.get<{ data: Document[] }>(`/api/meetings/${id}/files`)
      .pipe(
        map(x => Document.parseObjects(x.data))
      );
  }

  getTasks(id: number) {
    return this.http.get<{ data: Task[] }>(`/api/meetings/${id}/tasks`)
      .pipe(
        map(x => Task.parseObjects(x.data))
      );
  }

  updateOwner(id: number, owner_id: number) {
    return this.http.put<any>(`/api/meetings/${id}/owner`, { owner_id });
    // Meeting will already be reloaded after this.
  }

  avoidUserUpdates() {
    this.justUpdated = true;
    setTimeout(() => {
      this.justUpdated = false;
    }, 5000);
  }

  // Get previous Meetings and Tasks from this Meeting's Stack.
  getStack(id: number, forNext = false): Observable<{meetings: Meeting[], tasks: Task[]}> {
    return this.http.get<any>(`/api/meetings/${id}/stack` + (forNext ? '/next' : ''))
      .pipe(
        map(x => ({
            meetings: Meeting.parseObjects(x.meetings),
            tasks: Task.parseObjects(x.tasks),
          }))
      );
  }

  respond(id: number, response: 0 | 1 | 2 | 3) {
    return this.http.put<any>(`/api/meetings/${id}/respond`, {response});
  }

  search(term: string, among: number[]|null) {
    const data = among ? {term, among} : {term};
    const hash = JSON.stringify(data);
    if (this.cachedSearch?.key === hash) {
      return of(this.cachedSearch.result);
    }
    this.cachedSearch = null;
    return this.http.post<number[]>(`/api/meetings/search`, data).pipe(
      tap(result => this.cachedSearch = { key: hash, result })
    );
  }

  checkToken(id: number, token: string) {
    this.storedToken = token;
    return this.http.post<any>(`/api/meetings/${id}/check-token`, {token});
  }

  claimMeetingInvitation(id: number, token: string) {
    return this.http.post<any>(`/api/meetings/${id}/claim-invitation`, {token});
  }

  takeThePen(id: number) {
    return this.http.post<any>(`/api/meetings/${id}/take-the-pen`, {});
  }

  createWithFormat(formatId: number): Meeting {
    const meeting = new Meeting();
    meeting.format_id = formatId;

    const circleId = this.circleRepo.getActiveId();
    if (circleId) {
      meeting.circles = [{
        id: circleId,
        swimlane_id: 0
      }];
    }

    // Set starting status.
    if (meeting.format_id === 1) {
      meeting.status = Meeting.STATUS_ONGOING;
      meeting.date_time = Math.floor(Date.now() / 1000);
      meeting.setDateAndTime();
    }

    return meeting;
  }

  protected documentsUpdated() {}
  protected documentDeleted() {}

}
