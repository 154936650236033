import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TraceClassDecorator } from '@sentry/angular';
import { HelperService } from 'app/services';

@Component({
  selector: 'flxion-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['auth-layout.component.scss']
})
@TraceClassDecorator()
export class AuthLayoutComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.helperService.storePartner(this.route.snapshot);
  }

}
