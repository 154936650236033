<footer class="main-footer">
  <div class="container">
    <p>
      <strong>Flxion.</strong><br>
      Get your fluid<br>
      organization<br>
      into action.
    </p>
  </div>
</footer>
