import { Pipe, PipeTransform } from '@angular/core';

/**
 * Get just the initials from a string. Actually, it only sends back the first letter.
 */
@Pipe({
  name: 'initials',
  pure: true
})
export class InitialsPipe implements PipeTransform {

  transform(value: string, args?: any[]): string {
    if (!value) {
      return '';
    }

    // Get the first letters of up to two words and capitalize them.
    const words = value.split(' ');
    let initials = '';
    for (let i = 0; i < 2 && i < words.length; i++) {
      initials += words[i].charAt(0).toUpperCase();
    }
    return initials;
  }

}
