import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatestWith, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { SpheresRepository } from 'app/repositories';
import { Circle, Sphere } from 'app/models';

@Component({
  selector: 'flxion-change-sphere',
  templateUrl: './change-sphere.component.html',
})
export class ChangeSphereComponent implements OnInit, OnDestroy {

  private stopper = new Subject<void>();

  filter = new FormControl('', Validators.required);
  filteredSpheres: Observable<Sphere[]>;
  oldSphereId = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public circle: Circle,
    private dialogRef: MatDialogRef<ChangeSphereComponent, { circleId: number, sphereId: number }>,
    public spheresRepo: SpheresRepository,
    private snack: MatSnackBar,
  ) { }

  ngOnInit() {
    if (this.circle.sphere_id && !this.circle.sphere?.canWrite) {
      this.snack.open('You cannot move this circle to another sphere because you have no editing rights on the current sphere.');
      this.dialogRef.close(null);
    }
    this.oldSphereId = this.circle.sphere_id;

    this.filter.setValue(this.circle.sphere);
    this.filteredSpheres = this.spheresRepo.spheres$.pipe(
      map(spheres => spheres.filter(sphere => sphere.canWrite)),
      combineLatestWith(this.filter.valueChanges.pipe(startWith(''))),
      map(([spheres, value]) => {
        if (value instanceof Sphere) return spheres;
        const uc = value.toUpperCase();
        return spheres.filter(
          sphere => sphere.code?.toUpperCase().startsWith(uc) || sphere.title?.toUpperCase().indexOf(uc) >= 0
        );
      }),
      takeUntil(this.stopper),
    );
  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

  get dirty() {
    return this.oldSphereId !== this.circle.sphere_id;
  }

  optionSelected(event) {
    const sphere = event.option.value as Sphere;
    this.circle.sphere_id = sphere?.id;
  }
  displayWith(value) {
    return value instanceof Sphere
      ? (value.code ? `${value.code}: ${value.title}` : value.title)
      : value;
  }
  onBlur() {
    if (this.filter.value instanceof Sphere) return;
    // Maybe the full prefix was typed. Let's check whether it matches.
    const uc = this.filter.value?.toUpperCase();
    const sphere = uc ? this.spheresRepo.spheres.find(sphere => sphere.code?.toUpperCase() === uc) : null;
    if (sphere) {
      this.circle.sphere_id = sphere?.id;
      this.filter.setValue(sphere);
    } else {
      this.filter.setErrors(this.filter.value && !(this.filter.value instanceof Sphere) ? {e: 1} : null);
    }
  }

  save() {
    if (this.dirty) {
      this.dialogRef.close({
        circleId: this.circle.id,
        sphereId: this.circle.sphere_id,
      });
    } else {
      this.dialogRef.close(null);
    }
  }

}
