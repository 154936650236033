import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Swimlane } from '../models';
import { SwimlanesRepository } from '../repositories';

@Injectable({
  providedIn: 'root'
})
export class SwimlaneService {

  constructor(
    private snack: MatSnackBar,
  ) {}

  /**
   * Edit swimlane (from overview page), or delete it if it's empty.
   */
  editSwimlane(
    swimlane: Swimlane,
    input: HTMLInputElement,
    swimlanesRepo: SwimlanesRepository,
    hasItems: boolean,
  ) {
    if (input.value !== swimlane.title) {
      if (input.value) {
        swimlanesRepo.updateSwimlane(swimlane.id, {title: input.value}).subscribe(
          () => this.snack.open('Swimlane title updated.', null, {duration: 2500})
        );
      } else {
        // Empty title, which is invalid to save.
        if (!hasItems) {
          // No items inside, so delete this one.
          swimlanesRepo.deleteSwimlane(swimlane.id).subscribe(
            () => this.snack.open('Swimlane removed.', null, {duration: 2500})
          );
        } else {
          // It has items, so reset value.
          this.snack.open('Swimlane title cannot be empty.', null, {duration: 3000});
          input.value = swimlane.title;
        }
      }
    } // Else: no change.
    input.blur();
  }

  addSwimlane(
    input: HTMLInputElement,
    swimlanesRepo: SwimlanesRepository,
  ) {
    swimlanesRepo.addSwimlane(input.value).subscribe(
      () => this.snack.open('Swimlane created.', null, {duration: 2500})
    );
    input.value = '';
    input.blur();
  }

}
