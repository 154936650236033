import { ChangeDetectorRef, ErrorHandler, Injectable } from '@angular/core';
import { captureException } from '@sentry/angular';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

    private errorSubject = new Subject();

    constructor() {
        this.errorSubject.pipe(
          debounceTime(250),
          distinctUntilChanged()
        ).subscribe(
          (error: Error | any) => {
            // A frontend update was deployed, causing this error, so reload the page.
            if (/Loading chunk [\d]+ failed/.test(error.message)) {
                console.warn('App updated! Reloading...');
                window.location.reload();
                return;
            }

            // All other errors.
            console.error(error);
            captureException(error.originalError || error.error || error);

            // https://github.com/angular/angular/issues/17010
            const debugCtx = error['ngDebugContext'];
            const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef);
            if (changeDetectorRef) {
              changeDetectorRef.attach();
            }
          }
        );
    }

    public handleError(error: Error | any): void {
        if (!error) {
            // This also happens when we cancelled a popup (because then we throw 0).
            // console.error('Unknown error.');
            return;
        }

        const debugCtx = error['ngDebugContext'];
        const changeDetectorRef = debugCtx && debugCtx.injector.get(ChangeDetectorRef);
        if (changeDetectorRef) {
            changeDetectorRef.detach();
        }
        this.errorSubject.next(error);
    }

}
