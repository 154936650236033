import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/en-gb';

@Pipe({
  name: 'moment',
  pure: true
})
export class MomentPipe implements PipeTransform {

  transform(value: string | number | Date | moment.Moment, dateFormat?: 'time and maybe date' | string): any {
    if (!value) {
      return '';
    }
    moment.locale('en-gb');
    const obj = typeof value === 'number'
      ? moment(value * 1000)
      : moment(value);

    if (dateFormat === 'time and maybe date') {
      if (obj < moment().subtract('16', 'hours')) {
        dateFormat = 'D MMM, HH:mm';
      } else {
        dateFormat = 'HH:mm';
      }
    }

    return obj.format(dateFormat || 'LL');
  }

}
