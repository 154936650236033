import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'flxion-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.css']
})
export class AppLoaderComponent {

  title: string;

  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) {}

}
