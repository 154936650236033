import * as moment from 'moment';
import { BaseModel } from './baseModel';
import { PlannerMode } from './types';
import { User } from './user';
import { Meeting } from './meeting';
import { TaskHistory } from './taskHistory';
import { authId } from 'app/common/helpers';

export type TaskStatus = 'todo' | 'todiscuss' | 'done' | 'wontdo';

export class Task extends BaseModel {

  id = 0;
  updated_at = 0;
  title = '';
  status: TaskStatus = 'todo';
  description = '';
  deadline = '';
  hard_deadline = false;
  sort = 0;
  users: User[] = [];

  chapter = ''; // deprecated; pivot with viewed meeting (TaskHistory)
  task_histories: TaskHistory[];
  creator_meeting: Meeting;

  // Planning values.
  planned_date: moment.Moment = null;
  plan_mode: PlannerMode = null;
  estimated_hours?: number = null;

  // Only used when sorting Tasks into Planning Columns.
  planIsoDate: string = null;
  faded = false;

  constructor(object?: Partial<Task>) {
    super();
    this.fill(object);

    if (object && typeof object === 'object') {
      // Make some data into true objects.
      if (this.users.length) {
        this.users = User.parseObjects(this.users);
      }
      if (object.hasOwnProperty('task_histories')) {
        this.task_histories = TaskHistory.parseObjects(this.task_histories);
      }
      if (object.hasOwnProperty('creator_meeting')) {
        this.creator_meeting = object.creator_meeting?.id ? new Meeting(object.creator_meeting) : null;
      }
      if (object.hasOwnProperty('planned_date')) {
        if (typeof this.planned_date === 'number') {
          // The following applies the device's timezone.
          this.planned_date = moment.unix(+this.planned_date);
        } else if (this.planned_date) {
          this.planned_date = moment(this.planned_date);
        }
      }
    }
  }

  get isPrivate(): boolean {
    return !this.creatorMeeting?.id;
  }

  get assignee(): User {
    return this.users.find(x => x.is_assignee);
  }
  get assignedToMe(): boolean {
    if (!this.assignee) {
      return false;
    }
    return this.assignee.id === authId();
  }

  get blockedUser(): User {
    return this.users.find(x => x.is_blocked);
  }

  get estimation(): string {
    if (!this.estimated_hours) {
      return '';
    }
    return Math.round(this.estimated_hours * 10) / 10 + ' h';
  }

  set taskHistories(task_histories: TaskHistory[]) {
    this.task_histories = task_histories;
    // delete this.creator_meeting; (deleting this removes the category color when opening)
  }
  get taskHistories() {
    return this.task_histories;
  }

  get creatorMeeting(): Meeting {
    if (this.creator_meeting) {
      return this.creator_meeting;
    }
    if (!this.task_histories) {
      return null;
    }
    return this.task_histories.find(x => x.action === 'create' && x.meeting)?.meeting;
  }

  get closed() {
    return this.status !== 'todo';
  }

  get statusIconClass() {
    return Task.status_icon_class(this.status);
  }

  get statusIconKey() {
    return Task.status_icon_key(this.status);
  }

  static status_icon_class(status: TaskStatus) {
    switch (status) {
      case 'done': return 'mat-color-primary';
      case 'todiscuss': return 'mat-color-accent';
      case 'wontdo': return 'mat-color-warn';
      default: return 'mat-color-lightgrey';
    }
  }

  static status_icon_key(status: TaskStatus) {
    switch (status) {
      case 'done': return 'check_circle_outline';
      case 'todiscuss': return 'help_outline';
      case 'wontdo': return 'highlight_off';
      default: return 'radio_button_unchecked';
    }
  }

}
