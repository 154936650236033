import { Component, Input } from '@angular/core';
import { User } from 'app/models';

@Component({
  selector: 'flxion-users-on-card',
  templateUrl: './users-on-card.component.html',
  styleUrls: ['./users-on-card.component.scss']
})
export class UsersOnCardComponent {

  @Input() users: User[] = null;
  @Input() showResponses = false;

  responseClass(user: User) {
    return {
      accepted: user.response == 1,
      declined: user.response == 2,
      tentative: user.response == 3,
      pending: !user.response,
    };
  }

  responseIcon(user: User) {
    if (user.response === 1) return 'check';
    if (user.response === 2) return 'clear';
    return 'more_horiz';
  }

  responseTooltip(user: User) {
    if (user.response === 1) return 'Invitation accepted';
    if (user.response === 2) return 'Invitation declined';
    if (user.response === 3) return 'Tentative';
    return 'No response yet';
  }
}
