<div class="text-right mt-4 mr-3" *ngIf="ns.notifications.length">
  <small><a (click)="markAllAsRead()">Mark all notifications as read</a></small>
</div>
<p *ngIf="!ns.notifications.length" class="text-center mt-4">You have no notifications.</p>
<mat-nav-list class="notification-list mt-3" role="list">
  <mat-list-item
      *ngFor="let n of ns.notifications"
      class="notific-item"
      [class.unread]="!n.read"
      role="listitem"
      routerLinkActive="open"
  >
    <mat-icon class="notific-icon mr-3" [fontSet]="icon(n).fontSet || null">{{ icon(n).icon }}</mat-icon> <!-- [color]="n.color" -->
    <a [routerLink]="n.url ? [n.url] : null" (click)="clickAction(n)">
      <div class="mat-list-text">
        <h4 class="message">{{ n.message }}</h4>
        <small class="time text-muted">{{ time(n) }}</small>
      </div>
    </a>
    <mat-icon
        class="ml-3"
        matSuffix
        *ngIf="!n.read && n.can_mark_as_read"
        style="font-size: .7em; width: auto;"
        matTooltip="Mark as read"
        (click)="markAsRead(n)"
    >radio_button_unchecked</mat-icon>
  </mat-list-item>
</mat-nav-list>
