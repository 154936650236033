<img *ngIf="user.picture_url" class="person-avatar" [src]="user.picture_url" alt="" [matTooltip]="showTooltip ? user.name : ''">
<div
    *ngIf="!user.picture_url"
    class="person-initials"
    [class.guest]="user.isMe && (user.is_guest || !user.id)"
    [matTooltip]='showTooltip ? user.name + (user.label ? "\n" + user.label : "") : ""'
>
  <svg viewBox="0 0 28 28">
    <text x="50%" y="54%" dominant-baseline="middle" text-anchor="middle">{{ user.is_guest ? 'Guest' : (user.name | initials) }}</text>
  </svg>
</div>
