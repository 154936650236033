<form (ngSubmit)="save()">
  <h1 matDialogTitle>{{ oldSphereId > 0 ? 'Change' : 'Assign to' }} sphere</h1>

  <div matDialogContent>
    <mat-form-field>
      <mat-label>Sphere</mat-label>
      <input
          placeholder="Sphere"
          matInput
          required
          #filterInput
          [formControl]="filter"
          [matAutocomplete]="ac"
          (blur)="onBlur()"
      >
      <mat-error>Please select a sphere.</mat-error>
      <mat-autocomplete
          #ac="matAutocomplete"
          (optionSelected)="optionSelected($event)"
          (closed)="filterInput.blur()"
          [displayWith]="displayWith"
      >
        <mat-option *ngFor="let sphere of filteredSpheres | async" [value]="sphere">{{
            sphere.code
                ? sphere.code + ': ' + sphere.title
                : sphere.title
          }}
          <mat-icon>keyboard_return</mat-icon>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div matDialogActions>
    <button *ngIf="dirty" mat-raised-button color="primary">Save</button>
    <button *ngIf="!dirty" mat-stroked-button>Close</button>
  </div>
</form>
