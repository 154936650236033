import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatTooltip } from '@angular/material/tooltip';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { CirclesRepository, MeetingsRepository, SettingsRepository, SpheresRepository } from 'app/repositories';
import { HelperService, NotificationService, MenuService, UserService, IMenuItem } from 'app/services';

@Component({
  selector: 'flxion-header-top',
  templateUrl: './header-top.component.html',
  styleUrls: ['./header-top.component.scss']
})
export class HeaderTopComponent implements OnInit, OnDestroy {

  menuItems: IMenuItem[];

  searchTermControl = new FormControl();
  get searchLabels$() { return this.meetingsRepo.searchLabels$ } // currently not used

  contextActive$ = this.settingsRepo.contextActive$;

  activeContext: string;
  activeContextTooltip = '';
  contextActiveCanToggle$ = this.settingsRepo.contextActiveCanToggle$;

  @Input() notificationPanel: MatSidenav;

  private stopper = new Subject<void>();

  constructor(
    private cd: ChangeDetectorRef,
    private location: Location,
    public menuService: MenuService,
    private meetingsRepo: MeetingsRepository,
    private circlesRepo: CirclesRepository,
    private spheresRepo: SpheresRepository,
    private settingsRepo: SettingsRepository,
    private userService: UserService,
    private notificationService: NotificationService,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.menuService.menuItems$.pipe(takeUntil(this.stopper)).subscribe(res => {
      res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
      const limit = 4;
      const mainItems: IMenuItem[] = res.slice(0, limit);
      if (res.length <= limit) {
        return this.menuItems = mainItems;
      }
      // Create a submenu icon if there are more than 4 items.
      const subItems: any[] = res.slice(limit, res.length - 1);
      mainItems.push({
        name: 'More',
        type: 'dropDown',
        tooltip: 'More',
        icon: 'more_horiz',
        sub: subItems
      });
      this.menuItems = mainItems;
    });

    this.meetingsRepo.searchTerm$.pipe(takeUntil(this.stopper)).subscribe(searchTerm => {
      if (searchTerm && !this.searchTermControl.value) {
        this.searchTermControl.setValue(searchTerm);
      }
    });

    this.searchTermControl.valueChanges.pipe(debounceTime(400)).subscribe(value => this.setSearchTerm(value));

    combineLatest([
      this.spheresRepo.activeSphere$,
      this.circlesRepo.activeCircle$,
    ]).pipe(takeUntil(this.stopper))
      .subscribe(([sphere, circle]) => {
        const parts: string[] = [];
        const tooltipParts: string[] = [];
        const placeholderText = ' '.repeat(16);

        if (sphere?.id) {
          parts.push(sphere?.code || sphere?.title || placeholderText);
          tooltipParts.push('Sphere:  ' + sphere?.title || '');
        } else if (circle?.id) {
          parts.push('__');
          tooltipParts.push(circle.getSphereTooltip());
        }

        if (circle?.id) {
          parts.push(circle.code || circle.title || placeholderText);
          tooltipParts.push('Circle:  ' + circle.title);
        }

        this.activeContextTooltip = tooltipParts.join('\n');
        this.activeContext = parts.join(' › ');
      }
    );
  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

  countUnreadNotifications() {
    return this.notificationService.countUnreadNotifications();
  }
  toggleNotification() {
    this.notificationPanel.toggle();
  }

  get user() {
    return this.userService.user;
  }

  register() {
    // Reload in order to make sure services will be restarted.
    location.href = '/register?returnUrl=' + this.location.path() + '&email=' + encodeURIComponent(this.user.email);
  }

  maybeGoBack($event: MouseEvent) {
    const target = $event.currentTarget as HTMLSpanElement;
    const a = target.parentElement as HTMLAnchorElement;
    if ((this.helperService.touching || /^((?!chrome|android).)*safari/i.test(navigator.userAgent))
      && a.classList.contains('active')
      && this.menuService.lastPage === '/meetings'
      && /[\d]/.test(window.location.href.substr(-1))
    ) {
      // We are using a touch device and/or Safari, which may not have an easy back button; we were on a meeting page,
      // and we came from the meeting overview page. So use 'go back', in order to keep scrolling position.
      $event.stopImmediatePropagation();
      $event.preventDefault();
      this.location.back();
      return false;
    }
    else if(a.href.endsWith('/meetings') && window.location.toString().endsWith('/meetings')) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }

  touchForTooltip(tooltip: MatTooltip) {
    if (this.helperService.touching) {
      tooltip?.show();
    }
  }

  setSearchTerm(term: string): void
  {
    term = term.trim().replace(/[."',]/g, ' ').replace(/\s+/g, ' ');
    if (term.length < 2 || !term) {
      term = '';
    }
    this.meetingsRepo.setSearchTerm(term);
  }

  toggleContextActive() {
    return this.settingsRepo.contextActive$.next(!this.settingsRepo.contextActive$.value);
  }

}
