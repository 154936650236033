export interface Plan {
  name: string;
  plan: string;
  price_setup: number;
  price_month: number;
  credits: number;
  gb: number;
  gb_overuse_price: number;
  level: number;
}

export const plans: Plan[] = [
  {
    name: 'pay-per-use',
    plan: 'pay-per-use',
    price_setup: 10,
    price_month: 0,
    credits: 0,
    gb: 0,
    gb_overuse_price: 0,
    level: 0,
  },
  {
    name: 'Continuous',
    plan: 'continuous',
    price_setup: 0,
    price_month: 5,
    credits: 75,
    gb: 10,
    gb_overuse_price: 1,
    level: 1,
  },
  {
    name: 'Professional',
    plan: 'professional',
    price_setup: 0,
    price_month: 15,
    credits: 250,
    gb: 100,
    gb_overuse_price: 0.1,
    level: 2,
  },
  {
    name: 'Secure',
    plan: 'secure',
    price_setup: 0,
    price_month: 75,
    credits: 250,
    gb: 100,
    gb_overuse_price: 0.1,
    level: 3,
  },
];
