import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './common/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './common/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { NavigationGuard } from './guards/navigation.guard';

export const rootRouterConfig: Routes = [
  // Visiting the root.
  // AuthGuard redirects to Register/Login or Meetings, depending on login status.
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [AuthGuard],
    pathMatch: 'full',
  },

  // Login, Register, Reset password, Order.
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'register',
        loadChildren: () =>
          import('./modules/auth/register/register.module').then((m) => m.RegisterModule),
        data: { title: 'Create an account' },
      },
      {
        path: 'login',
        canDeactivate: [NavigationGuard],
        loadChildren: () =>
          import('./modules/auth/login/login.module').then((m) => m.LoginModule),
        data: { title: 'Log in' },
      },
      {
        path: 'password/:token',
        loadChildren: () =>
          import('./modules/auth/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
        data: { title: 'Reset password' },
      },
      { path: 'order', redirectTo: 'order/continuous', pathMatch: 'full' },
      {
        path: 'order/:plan',
        loadChildren: () =>
          import('./modules/order/order.module').then((m) => m.OrderModule),
        data: { title: 'Order' },
      },
    ],
  },

  {
    path: 'logout',
    canActivate: [NavigationGuard],
    children: []
  },

  // Logged-in paths.
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: 'spheres',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/spheres/spheres.module').then(m => m.SpheresModule),
        data: { title: 'My World' },
      },
      { path: 'world', redirectTo: 'spheres' },
      {
        path: 'circles',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/circles/circles.module').then(m => m.CirclesModule),
        data: { title: 'My Priorities' },
      },
      {
        path: 'meetings',
        // No canActivate here: this is done in meetings.routing because it needs
        // to be rechecked when moving to the meetings overview for guests.
        loadChildren: () =>
          import('./modules/meetings/meetings.module').then(m => m.MeetingsModule),
        data: { title: 'My Meetings' },
      },
      {
        path: 'tasks',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/tasks-planning/tasks-planning.module').then(m => m.TasksPlanningModule),
        data: { title: 'My Planning' },
      },
      {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/user/user.module').then(m => m.UserModule),
        data: { title: 'Profile' },
      },
      {
        // This handles the /account/ route for logged-in users, but uses Profile Component.
        path: 'account',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./modules/user/user.module').then(m => m.UserModule),
        data: { title: 'Account' },
      },
    ],
  },

  // Anything else: redirect to Meetings (which may in turn redirect to login).
  {
    path: '**',
    redirectTo: 'meetings',
  },

];
