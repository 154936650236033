import { BaseModel } from './baseModel';

export class Swimlane extends BaseModel {

  id = 0;
  title = '';
  sort = 0;
  is_default = false;

  constructor(object?: Partial<Swimlane> | string) {
    super();
    if (typeof object === 'string') {
      object = { title: object };
    }
    this.fill(object);
  }

}

export type SwimlaneFor<T> = {

  swimlane: Swimlane;
  items: T[];

  canEdit?: boolean;
  canAdd?: boolean;
  label?: string;

}

export const MySwimlanesForCircles = [
  new Swimlane({id: 1, sort: 1, title: 'Top priority'}),
  new Swimlane({id: 2, sort: 2, title: 'Daily'}),
  new Swimlane({id: 3, sort: 3, title: 'Weekly'}),
  new Swimlane({id: 4, sort: 4, title: 'Monthly'}),
  new Swimlane({id: 7, sort: 5, title: 'Annually'}),
  new Swimlane({id: 5, sort: 6, title: 'Whenever'}),
  new Swimlane({id: 6, sort: 7, title: 'Never'}),
];
