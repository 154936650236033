<!-- Form or view of the main Task data -->
<flxion-task-main
    [task$]="task$"
    [meeting]="meeting"
    [taskHistories$]="taskHistories$"
    [onTaskSave]="onTaskSave"
></flxion-task-main>

<!-- Planning and status update, by the Assignee on a Meeting Task -->
<flxion-task-planner
    *ngIf="task.id && task.assignedToMe && !task.closed && (task.isPrivate || !canWrite)"
    [@slideUp]
    [task]="task"
    [inMeeting]="!!meeting"
    [plannerData]="data.plannerData"
></flxion-task-planner>

<!-- TaskHistories -->
<mat-card *ngIf="!task.isPrivate && (shownTaskHistories.length || task.assignedToMe && !canWrite)">
  <flxion-task-status-update
      *ngIf="task.assignedToMe && !canWrite"
      [task]="task"
      [statusUpdate$]="statusUpdate$"
  ></flxion-task-status-update>
  <hr *ngIf="shownTaskHistories.length && task.assignedToMe && !canWrite">
  <div class="histories" *ngIf="shownTaskHistories.length">
    <flxion-task-history
        *ngFor="let hist of shownTaskHistories"
        [meetingId]="meeting?.id"
        [taskHistory]="hist"
        [task]="task"
    ></flxion-task-history>
  </div>
</mat-card>

<!--<button-->
<!--    mat-mini-fab-->
<!--    class="mat-elevation-z0"-->
<!--    color=""-->
<!--    (click)="dialogRef.close()"-->
<!--&gt;<mat-icon>close</mat-icon></button>-->
