import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COMMA, ENTER, SEMICOLON, TAB } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { CirclesRepository } from 'app/repositories';
import { Category, SettingsService, MeetingService, UserService, CategorizationData } from 'app/services';
import { Meeting } from 'app/models';

@Component({
  selector: 'flxion-meeting-categorize',
  templateUrl: './meeting-categorize.component.html',
  styleUrls: ['./meeting-categorize.component.scss']
})
export class MeetingCategorizeComponent implements OnInit {

  separatorKeysCodes: number[] = [ENTER, COMMA, SEMICOLON, TAB];

  @ViewChild('chipInput', {static: true})
  private chipInput: ElementRef<HTMLInputElement>;

  knownLabels: string[] = [];
  labelFilter = new FormControl();
  filteredLabels: Observable<string[]>;
  labels: string[] = [];

  categoryId = 0;
  knownCategories: Category[];
  get category() {
    return this.knownCategories?.find(x => x.id == this.categoryId);
  }

  oldLabels = '';
  oldCategoryId = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public meeting: Meeting,
    private dialogRef: MatDialogRef<MeetingCategorizeComponent, CategorizationData>,
    private circlesRepo: CirclesRepository,
    private meetingService: MeetingService,
    private userService: UserService,
    private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.labels = this.meeting.labels;
    this.oldLabels = JSON.stringify(this.labels);

    this.loadKnownLabels();
    this.loadCategories();

    // Store original values, to determine whether to save later.
    this.oldCategoryId = this.categoryId = this.meeting.category;
  }

  get dirty() {
    return this.oldLabels !== JSON.stringify(this.labels) || this.oldCategoryId !== this.categoryId;
  }

  private loadCategories() {
    this.settingsService.getCategories().subscribe(categories => {
      this.knownCategories = categories.filter(x => x.order > 0);
    });
  }

  private loadKnownLabels() {
    // Get the known simple labels. Then merge them with the Circle titles.
    this.settingsService.getKnownLabels().subscribe(data => {
        this.knownLabels = data;
        this.filteredLabels = this.labelFilter.valueChanges
          .pipe(
            startWith(null),
            map(value => this._filter(value))
          );
      });
  }

  addLabel(): void {
    const value = this.labelFilter.value;

    // If not empty, add the typed label.
    if ((value || '').trim()) {
      this.labels.push(value.trim());
    }

    this.resetFilter();
  }

  removeLabel(label: string): void {
    const index = this.labels.indexOf(label);

    if (index >= 0) {
      this.labels.splice(index, 1);
    }
  }

  chooseLabel(event: MatAutocompleteSelectedEvent): void {
    // Add selected label to list.
    const label: string = event.option.value;
    if (!this.labelInList(label)) {
      this.labels.push(label);
    }
    // Reset filter.
    this.resetFilter();
  }

  // Check whether the given label is already in the list of labels.
  private labelInList(newLabel: string) {
    return this.labels.some(label => {
      if (label.toUpperCase() === newLabel.toUpperCase()) {
        return true;
      }
    });
  }

  private resetFilter() {
    this.chipInput.nativeElement.value = '';
    this.labelFilter.setValue(null);
  }

  private _filter(value: string): string[] {
    if (!value) {
      return this.knownLabels;
    }

    const lc = value.toLowerCase();
    return this.knownLabels.filter(
      label => label.toLowerCase().includes(lc)
    );
  }

  save() {
    if (this.dirty) {
      if (this.oldLabels !== JSON.stringify(this.labels)) {
        this.settingsService.clearLabelsCache();
      }
      this.dialogRef.close({
        meetingId: this.meeting.id,
        labels: this.labels,
        category: this.categoryId,
      });
    } else {
      this.dialogRef.close(null);
    }
  }

}
