import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom } from 'rxjs';

import { isValidEmail } from 'app/common/helpers';
import { CircleService, HelperService, MeetingService, SphereService, UserService } from 'app/services';
import { User } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private user: User;

  constructor(
    private router: Router,
    private userService: UserService,
    private meetingService: MeetingService,
    private circleService: CircleService,
    private sphereService: SphereService,
    private helperService: HelperService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.user = this.userService.user;
    if (this.user.id) {
      return this.ifLoggedIn(state);
    } else {
      return this.ifNotLoggedIn(route, state);
    }
  }

  private ifLoggedIn(state: RouterStateSnapshot) {
    if (this.user.is_guest) {
      // Guests are only allowed to load one page. The Meeting they were invited to.
      const forcedUrl = '/meetings/' + this.user.guest_meeting_id;
      if (state.url !== forcedUrl) {
        this.helperService.redirectGuestBackTo(forcedUrl);
        return false;
      }
      return true;
    }
    if (AuthGuard.tryingRoot(state)) {
      // Redirect to /meetings from root.
      this.router.navigate(['/meetings']);
      return false;
    }
    // Load the requested page.
    return true;
  }

  private async ifNotLoggedIn(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // Not logged in, so we won't load the requested page.

    // Check whether we came from a partner.
    this.helperService.storePartner(route);

    // If trying the root, redirect to Login.
    if (AuthGuard.tryingRoot(state)) {
      this.router.navigate(['/login']);
      return false;
    }

    // Trying a meeting invitation link, with token, shareCode or email.
    if (state.url.startsWith('/meetings/') && route.fragment) {
      const result = await this.checkMeetingInvitation(route, state);
      if (result !== null) {
        return result;
      }
      // If it's null, nothing valid was found, and we proceed to login.
    }

    // Trying a sphere/circle invitation link, with token or email.
    if ((state.url.startsWith('/circles/') || state.url.startsWith('/spheres/')) && route.fragment) {
      const result = await this.checkContextInvitation(route, state);
      if (result !== null) {
        return result;
      }
      // If it's null, nothing valid was found, and we proceed to login.
    }

    // Redirect to login screen, and after that redirect to requested page.
    this.router.navigate(['/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

  private async checkMeetingInvitation(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    // We'll send these queryParams along to the Register page (if we go there).
    let queryParams: Params = {
      returnUrl: state.url.substring(0, state.url.indexOf('#'))
    };

    if (isValidEmail(route.fragment)) {
      // Includes a valid email, so pre-fill email.
      queryParams.email = route.fragment;
    } else if (route.fragment.match(/^[a-z0-9]{5,6}$/)) {
      // Includes a share_code, so pre-fill shareCode.
      queryParams.shareCode = route.fragment;
    } else if (route.fragment.match(/^[a-z0-9]{30,32}$/)) {
      // Includes a token. Look it up via API.
      // If valid, login as guest and redirect to the Meeting. Otherwise, to register with pre-filled email.
      const token = route.fragment;
      const meetingId = +state.url.match(new RegExp('^/meetings/([\\d]+)'))[1];
      let result;
      await firstValueFrom(this.meetingService.checkToken(meetingId, token))
        .then(x => result = x)
        .catch(() => this.router.navigate(['/login'], {queryParams}));
      if (result.user) {
        // Login as guest.
        this.user = this.userService.setGuestAuth(result.user);
        this.router.navigate([queryParams.returnUrl]);
        return false;
      } else if (result.invited_email) {
        // Require registration with the associated email.
        queryParams.email = result.invited_email;
      }
    } else {
      queryParams = null;
    }

    if (queryParams) {
      this.router.navigate(['/register'], {queryParams});
      return false;
    }

    // No valid email/token/share_code, so not a valid invitation link, so direct to login.
    return null;
  }

  private async checkContextInvitation(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const resourceType = state.url.startsWith('/circles/') ? 'circle' : 'sphere';

    // We'll send these queryParams along to the Register page (if we go there).
    let queryParams: Params = {
      returnUrl: state.url.substring(0, state.url.indexOf('#'))
    };

    if (isValidEmail(route.fragment)) {
      // Includes a valid email, so pre-fill email.
      queryParams.email = route.fragment;
    } else if (route.fragment.substring(2).match(/^[a-z0-9]{30,32}$/) && route.fragment.substring(0, 2) === resourceType[0] + '-') {
      // Includes a token. Look it up via API.
      // If valid, redirect to register with pre-filled email.
      const token = route.fragment.substring(2);
      const resourceId = +state.url.match(new RegExp('^/(circles|spheres)/([\\d]+)'))[2];
      let result;
      const service = resourceType == 'circle'
        ? this.circleService
        : this.sphereService;
      const decline = route.queryParamMap.get('a') === 'decline';
      await firstValueFrom(service.checkToken(resourceId, token, decline))
        .then(x => result = x)
        .catch(() => this.router.navigate(['/login'], {queryParams}));
      if (result.invited_email) {
        // Require registration with the associated email.
        queryParams.email = result.invited_email;
      }
      if (result.declined) {
        // Indicate that the decline was successful.
        queryParams.declined = true;
      }
    } else {
      queryParams = null;
    }

    if (queryParams) {
      this.router.navigate(['/register'], {queryParams});
      return false;
    }

    // No valid email/token/share_code, so not a valid invitation link, so direct to login.
    return null;
  }

  /**
   * Is the visitor trying to access the root?
   * E.g. / or /?partner= or /#123 but not /meetings or /tasks or /profile
   */
  private static tryingRoot(state: RouterStateSnapshot): boolean {
    return state.url.length < 2 || state.url[1] === '?' || state.url[1] === '#';
  }

}
