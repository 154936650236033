import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { ConfirmService } from 'app/common/confirm/confirm.service';
import { User } from 'app/models';

@Component({
  selector: 'flxion-member-details',
  templateUrl: './member-details.component.html',
  styleUrls: ['./member-details.component.scss']
})
export class MemberDetailsComponent implements OnInit, OnDestroy {

  private stopper = new Subject<void>();
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: User,
    private dialogRef: MatDialogRef<MemberDetailsComponent, { label?: string, access_level?: number, delete?: true }>,
    private fb: FormBuilder,
    private confirmService: ConfirmService,
  ) { }

  ngOnInit() {
    let fields: any = {
      label: [this.user.label]
    };
    if (!this.user.isMe) {
      fields.access_level = [this.user.access_level, Validators.required];
    }
    this.form = this.fb.group(fields);
  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

  save() {
    if (this.form.dirty) {
      this.dialogRef.close(this.form.value);
    } else {
      this.dialogRef.close(null);
    }
  }

  delete() {
    return this.confirmService.confirm().subscribe(yes => {
      if (yes) {
        this.dialogRef.close({ delete: true });
      }
    });
  }

}
