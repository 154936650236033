import { Component, Input } from '@angular/core';
import { User } from 'app/models';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {

  @Input() user: User;
  @Input() showTooltip = false;

}
