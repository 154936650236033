import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';

import { SnackbarAction, SnackbarComponent } from '../notifications/snackbar.component';
import { Notification } from 'app/models';
import { UserService } from './user.service';

/**
 * This service is different from the Meeting and Task service etc,
 * because those are just wrappers for the backend API, while this
 * one actually stores the items internally for external consumption.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notifications: Notification[] = [];

  public set notifications(value: Notification[]) {
    this.userService.emailVerified = !this.emailNotVerifiedNotification();
    this._notifications = value;
  }
  public get notifications(): Notification[] {
    return this._notifications;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    public snack: MatSnackBar
  ) {
    this.getAll().subscribe(data => {
      this.notifications = data.filter(x => x); // strip out null values
      if (!this.userService.user.is_new && !this.userService.emailVerified) {
        this.alertAboutEmailVerification();
      }
    });
  }

  private getAll() {
    return this.http.get<any>(`/api/notifications`)
      .pipe(
        map(x => <Notification[]> x.data)
      );
  }

  emailNotVerifiedNotification() {
    return this.notifications.find(n => n.type === 'email-verif');
  }

  markAsRead(n: Notification) {
    const id = n.id;
    if (!n.can_mark_as_read) {
      return false;
    }
    n.read = true;
    this.notifications = [...this.notifications];

    this.http.put<any>(`/api/notifications/${id}`, {read: 1})
      .subscribe();
  }

  markAsUnRead(n: Notification) {
    const id = n.id;
    this.notifications.find(x => x.id = id).read = false;
    this.notifications = [...this.notifications];

    return this.http.put<any>(`/api/notifications/${id}`, {read: 0})
      .subscribe();
  }

  markAllAsRead() {
    this.notifications.filter(x => !x.read).forEach(x => this.markAsRead(x));
    this.notifications = [...this.notifications];
  }

  countUnreadNotifications() {
    return this.notifications.filter(x => !x.read).length;
  }

  // This happens with the email verification notification, if it's found that it's actually already verified.
  delete(n: Notification) {
    const list = this.notifications.filter(x => x.id !== n.id);
    this.notifications = [...list];
  }

  resendEmailVerification() {
    // Returns status 202 on success, or 204 if already verified.
    // It may also return a 429 error in case of too many requests.
    return this.http.post<any>('/auth/email/resend', {}, {observe: 'response'}).subscribe({
      next: res => {
        if (res.status === 202) {
          this.snack.open('Verification email sent.', null, {duration: 2500});
        } else if (res.status === 204) {
          this.delete(this.emailNotVerifiedNotification());
          this.snack.open('Email address is verified.', null, {duration: 3500});
        }
      },
      error: err => {
        if (err.status === 429) {
          this.snack.open('Too many requests. Please try again in a minute.', 'OK');
        }
      }
    });
  }

  private alertAboutEmailVerification() {
    const actions: SnackbarAction[] = [
      {
        label: 'Resend email',
        handler: () => {
          this.resendEmailVerification();
        },
      },
      {
        label: 'OK',
      },
    ];
    this.snack.openFromComponent(
      SnackbarComponent,
      {
        data: {
          message: 'Verify your email address to complete registration.',
          actions
        },
        verticalPosition: 'top'
      }
    )
  }

}
