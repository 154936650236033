import { HttpClient } from '@angular/common/http';
import { Store } from '@ngneat/elf';
import { map, tap } from 'rxjs/operators';
import { Circle, Sphere, Swimlane } from 'app/models';
import { addEntities, deleteEntities, getAllEntities, setEntities } from '@ngneat/elf-entities';
import { byPropsOf } from 'app/common/sort';

export abstract class SwimlanesRepository {

  protected abstract endpointURL: string;
  protected abstract http: HttpClient;

  // Only used by SettingsRepository.
  protected swimlanesStore: Store = null;

  // These are defined in Circle and Sphere Repository, but not in SettingsRepository, so not abstract.
  protected getActiveId(): number|null { return null; }
  protected get(id: number): Circle | Sphere { return null; }
  protected updateLocal(id: number, data): void {}

  get endPointPath() {
    const parentId = this.getActiveId();
    return this.endpointURL + (parentId ? '/' + parentId : '');
  }

  addSwimlane(title: string) {
    const parentId = this.getActiveId();
    return this.http.post<any>(`/api/${this.endPointPath}/swimlanes`, { title })
      .pipe(
        map(x => x.data),
        tap(swimlanes => {
          // The output differs: for Circles and Sphere, it's the full list,
          // but otherwise (who has swimlanesStore), only the single one.
          if (this.swimlanesStore) {
            // 'swimlanes' is actually just one swimlane in this case.
            this.swimlanesStore.update(addEntities(swimlanes));
          } else {
            this.updateLocal(parentId, {swimlanes});
          }
        }),
      );
  }

  updateSwimlane(swimlaneId: number, data: { title?: string, sort?: number, is_default?: boolean }) {
    const parentId = this.getActiveId();
    return this.http.put<any>(`/api/${this.endPointPath}/swimlanes/${swimlaneId}`, data)
      .pipe(
        map(x => x.data),
        tap(result => {
          // The output differs: for Circles and Sphere, it's the full list,
          // but otherwise (who has swimlanesStore), only the single one.
          if (this.swimlanesStore) {
            // We don't use updateEntities to update just this single one,
            // because we want to store the list with the correct sorting.
            let swimlanes = this.swimlanesStore.query(getAllEntities());
            swimlanes = swimlanes
              .map(s => s.id === result.id ? new Swimlane(result) : s)
              .sort(byPropsOf<Swimlane>(['sort']));
            this.swimlanesStore.update(setEntities(swimlanes));
          } else {
            const swimlanes = result.sort(byPropsOf<Swimlane>(['sort']));
            this.updateLocal(parentId, {swimlanes});
          }
        }),
      );
  }

  deleteSwimlane(swimlaneId: number) {
    const parentId = this.getActiveId();
    return this.http.delete<any>(`/api/${this.endPointPath}/swimlanes/${swimlaneId}`)
      .pipe(
        tap(() => {
          if (this.swimlanesStore) {
            this.swimlanesStore.update(deleteEntities(swimlaneId));
          } else {
            this.updateLocal(
              parentId,
              {swimlanes: this.get(parentId).swimlanes.filter(sw => sw.id != swimlaneId)});
          }
        })
      );
  }

}
