import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { catchError, mergeMap } from 'rxjs';

import { ConfirmService } from '../common/confirm/confirm.service';
import { DocumentService } from './document.service';
import { ChangeSwimlaneComponent } from 'app/common/change-swimlane/change-swimlane.component';
import { Document, Sphere } from 'app/models';
import { SpheresRepository, SettingsRepository } from 'app/repositories';

@Injectable({
  providedIn: 'root'
})
export class SphereService extends DocumentService {

  protected endpointName = 'spheres';

  constructor(
    protected http: HttpClient,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private repo: SpheresRepository,
    private settingsRepo: SettingsRepository,
    private confirm: ConfirmService,
  ) {
    super();
  }

  checkToken(id: number, token: string, decline: boolean) {
    return this.http.post<any>(`/api/spheres/${id}/check-token`, {token, decline});
  }

  uiChangeSwimlane(sphere: Sphere) {
    return this.dialog.open(ChangeSwimlaneComponent, {
      width: '480px',
      autoFocus: false,
      data: {
        title: sphere.title,
        swimlaneId: sphere.swimlane_id,
        swimlanes: this.settingsRepo.swimlanes,
      },
    }).afterClosed().pipe(
      mergeMap((data) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.updateSwimlaneId(sphere.id, +data.swimlaneId);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Swimlane association could not be saved.', 'OK');
        }
        throw err;
      })
    );
  }

  protected documentsUpdated(id: number, documents: Document[]) {
    this.repo.updateLocal(id, { documents });
  }
  protected documentDeleted(id: number, docId: number) {
    const cur = this.repo.get(id);
    this.repo.updateLocal(id, { documents: cur.documents?.filter(d => d.id !== docId) });
  }

  uiLeaveSphere(sphere: Sphere) {
    return this.confirm.confirm({
      message: 'Please confirm that you do not wish to be a participant of the sphere called \'' + sphere.title + '\'.'
    }).pipe(
      mergeMap((data) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.leave(sphere.id);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Your action could not be executed. Please try again.', 'OK');
        }
        throw err;
      })
    );
  }

  uiRejoinSphere(sphere: Sphere) {
    return this.confirm.confirm({
      title: 'Rejoin sphere?',
      message: 'You have previously left this sphere. Would you like to rejoin?'
    }).pipe(
      mergeMap((data) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.rejoin(sphere.id);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Your action could not be executed. Please refresh to try again.', 'OK');
        }
        throw err;
      })
    );
  }

}
