<form (ngSubmit)="save()">
  <h1 matDialogTitle>{{ oldCircleIds.length > 2 ? 'Change' : 'Assign to' }} circle</h1>

  <div matDialogContent>
    <mat-form-field *ngIf="selectableCircles?.length || circles?.length; else no_circles">
      <mat-label>Circle</mat-label>
      <mat-chip-list #chipList>
        <mat-chip
            *ngFor="let circle of circles"
            (removed)="removeCircle(circle)"
        >
          <span class="prefix">{{ circle.getSphereCode() }}</span>
          <span class="code_" *ngIf="circle.code">{{ circle.code }}</span>
          <span class="title">{{ circle.title }}</span>
          <mat-icon matChipRemove *ngIf="circle.canWrite || meeting.isOwner">cancel</mat-icon>
        </mat-chip>
        <input
            placeholder="Choose a circle..."
            [formControl]="circleFilter"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
        >
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseCircle($event)">
        <mat-option *ngFor="let circle of filteredCircles | async" [value]="circle">
          <span class="prefix">{{ circle.getSphereCode() }}</span>
          <span class="code_" *ngIf="circle.code">{{ circle.code }}</span>
          <span class="title">{{ circle.title }}</span>
          <span style="flex: 9"></span>
          <mat-icon>keyboard_return</mat-icon>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <ng-template #no_circles><p>You cannot assign this meeting to any circle.</p></ng-template>
  </div>

  <div matDialogActions>
    <button *ngIf="dirty" mat-stroked-button (click)="cancel()" type="button">Cancel</button>
    <button *ngIf="dirty" mat-raised-button color="primary">Save</button>
    <button *ngIf="!dirty" mat-stroked-button>Close</button>
  </div>
</form>
