<form (ngSubmit)="save()">
  <h1 matDialogTitle class="has-spacer">
    <span>Organize</span>
    <span class="spacer"></span>
    <mat-icon style="cursor:help" matTooltip="How you organize your meetings is only visible to you. Configure your categories via your Settings page.">info_outline</mat-icon>
  </h1>

  <div matDialogContent>
    <mat-form-field class="category">
      <mat-label>Category</mat-label>
      <mat-select [(value)]="categoryId">
        <mat-select-trigger>
          <span class="color-box bg-color-{{categoryId}}"></span>
          <span class="cat-name">{{ category?.name }}</span>
        </mat-select-trigger>
        <mat-option>None</mat-option>
        <mat-option *ngFor="let cat of knownCategories" [value]="cat.id" class="cat-option">
          <span class="color-box bg-color-{{cat.id}}"></span>
          <span class="cat-name">{{ cat.name }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="labels">
      <mat-label>Labels</mat-label>
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let label of labels" (removed)="removeLabel(label)">
          {{ label }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
            placeholder="New label..."
            #chipInput
            [formControl]="labelFilter"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addLabel()"
            matTooltip="Press enter to select or create a label."
        >
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="chooseLabel($event)">
        <mat-option *ngFor="let label of filteredLabels | async" [value]="label">
          {{ label }}
          <mat-icon>keyboard_return</mat-icon>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div matDialogActions>
    <button *ngIf="dirty" mat-raised-button color="primary">Save</button>
    <button *ngIf="!dirty" mat-stroked-button>Close</button>
  </div>
</form>
