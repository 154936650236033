<form (ngSubmit)="submit()">

  <h1 matDialogTitle class="mb-2">Transfer meeting to new owner</h1>
  <p>Choose an attendee to transfer the ownership of this meeting to.<br>
    <b>This is a permanent action. You will no longer have editing rights.</b></p>

  <div mat-dialog-content>
    <mat-form-field class="detail-assigned">
      <mat-label>New owner</mat-label>
      <input
          matInput
          #userAssigned
          [formControl]="userFilter"
          [matAutocomplete]="autoComplete"
      >
      <mat-autocomplete
          #autoComplete="matAutocomplete"
          (optionSelected)="assignedSelected($event)"
          (closed)="userAssigned.blur()"
      >
        <mat-option *ngFor="let userOption of filteredUsers | async" [value]="userOption">
          {{ userOption.name || userOption.email }}
          <mat-icon>keyboard_return</mat-icon>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div mat-dialog-actions class="mt-2 has-spacer">
    <span class="spacer"></span>
    <button type="button" mat-stroked-button (click)="dialogRef.close(false)">Cancel</button>
    <button type="submit" mat-raised-button color="primary">Save</button>
  </div>

</form>

