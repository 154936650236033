import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { catchError, mergeMap, Observable } from 'rxjs';

import { ChangeSphereComponent } from '../modules/circles/change-sphere/change-sphere.component';
import { ChangeSwimlaneComponent } from '../common/change-swimlane/change-swimlane.component';

import { ConfirmService } from '../common/confirm/confirm.service';
import { DocumentService } from './document.service';
import { CirclesRepository, SpheresRepository } from 'app/repositories';
import { Circle, Document, MySwimlanesForCircles, Swimlane } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class CircleService extends DocumentService {

  protected endpointName = 'circles';

  constructor(
    protected http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private snack: MatSnackBar,
    private repo: CirclesRepository,
    private spheresRepo: SpheresRepository,
    private confirm: ConfirmService,
  ) {
    super();
  }

  checkToken(id: number, token: string, decline: boolean) {
    return this.http.post<any>(`/api/circles/${id}/check-token`, {token, decline});
  }

  uiChangeSphere(circle: Circle) {
    return this.dialog.open(ChangeSphereComponent, {
      width: '480px',
      autoFocus: false,
      data: circle,
    }).afterClosed().pipe(
      mergeMap((data) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.updateSphereId(circle.id, +data.sphereId);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Sphere association could not be saved.', 'OK');
        }
        throw err;
      })
    );
  }

  uiLeaveCircle(circle: Circle) {
    return this.confirm.confirm({
      title: 'Leave this circle?',
      message: 'Please confirm that you do not wish to be a participant of the circle called \'' + circle.title + '\'.'
    }).pipe(
      mergeMap((data) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.leave(circle.id);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Your action could not be executed. Please try again.', 'OK');
        }
        throw err;
      })
    );
  }

  uiRejoinCircle(circle: Circle) {
    return this.confirm.confirm({
      title: 'Rejoin this circle?',
      message: 'You have previously left this circle. Would you like to rejoin?'
    }).pipe(
      mergeMap((data) => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return this.repo.rejoin(circle.id);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Your action could not be executed. Please refresh to try again.', 'OK');
        }
        throw err;
      })
    );
  }

  uiChangeSwimlane(circle: Circle, contextActive: boolean) {
    let data: { title: string, swimlaneId: number, swimlanes?: Swimlane[] };
    let handleChoice: (swimlaneId: number) => Observable<any>;

    if (contextActive) {
      const activeSphere = this.spheresRepo.activeSphere;
      data = {
        title: circle.title,
        swimlaneId: circle.swimlane_id,
        swimlanes: activeSphere.swimlanes,
      };
      handleChoice = swimlaneId => this.repo.updateSwimlaneId(circle.id, activeSphere.id, swimlaneId);
    } else {
      data = {
        title: circle.title,
        swimlaneId: circle.my_swimlane_id,
        swimlanes: MySwimlanesForCircles,
      };
      handleChoice = mySwimlaneId => this.repo.updateMySwimlaneId(circle.id, mySwimlaneId);
    }

    return this.dialog.open(ChangeSwimlaneComponent, {
      width: '480px',
      autoFocus: false,
      data,
    }).afterClosed().pipe(
      mergeMap(data => {
        if (!data) {
          // Cancelled action.
          throw 0;
        }
        return handleChoice(+data.swimlaneId);
      }),
      catchError(err => {
        if (err !== 0) {
          this.snack.open('Swimlane association could not be saved.', 'OK');
        }
        throw err;
      })
    );
  }

  protected documentsUpdated(id: number, documents: Document[]) {
    this.repo.updateLocal(id, { documents });
  }
  protected documentDeleted(id: number, docId: number) {
    const cur = this.repo.get(id);
    this.repo.updateLocal(id, { documents: cur.documents?.filter(d => d.id !== docId) });
  }

}
