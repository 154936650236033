import { Component, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { trigger, transition, animate, style } from '@angular/animations'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';

import { TaskDetailsData } from 'app/models/types';
import { Meeting, Task, TaskHistory } from 'app/models';
import { TaskService } from 'app/services';

@Component({
  selector: 'flxion-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
  animations: [
    trigger('slideUp', [
      transition(':enter', [
        style({height: '0'}),
        animate('200ms ease-in', style({height: '114px'})),
        style({height: 'auto'}),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({height: '0'}))
      ])
    ])
  ]
})
export class TaskDetailsComponent {

  form: FormGroup;
  task: Task;
  taskHistories: TaskHistory[];
  onTaskSave: EventEmitter<Task>;

  task$: BehaviorSubject<Task>;
  taskHistories$ = new BehaviorSubject<TaskHistory[]>([]);
  statusUpdate$ = new Subject<TaskHistory>();

  meeting: Meeting = null;
  get canWrite() { return !this.task.id || this.task.isPrivate || this.meeting?.canWrite; }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TaskDetailsData,
    public dialogRef: MatDialogRef<TaskDetailsComponent>,
    private fb: FormBuilder,
    private taskService: TaskService,
  ) {
    this.meeting = this.data.meeting;
    this.onTaskSave = this.data.onTaskSave;
    this.taskHistories$.subscribe(histories => this.taskHistories = histories);

    // The following construction allows the task to be updated from the TaskTable.
    this.task$ = new BehaviorSubject<Task>(this.data.task);
    this.task$.subscribe(task => {
      this.task = task;
      if (this.task.id) {
        this.taskService.getHistory(this.task.id).subscribe(
          histories => {
            // Saved into local property because this.task gets overwritten by periodic updates.
            this.taskHistories$.next(histories);

            let ownHistory;
            if (this.meeting) {
              ownHistory = histories.find(x => x.meeting && x.meeting.id === this.meeting.id);
            }

            if (histories
              && histories[0].action === 'status'
              && !(this.task.closed && !histories[0].data.get('closed'))
              && this.task.assignedToMe
              && (!this.meeting || ownHistory)
            ) {
              // The most recent TaskHistory is my status update,
              // and the task is not closed (unless by this TaskHistory),
              // so make it editable.
              // Unless we're in a meeting which has no TaskHistory yet.
              this.statusUpdate$.next(histories[0]);
            }
          }
        );
      }
    });
  }

  get shownTaskHistories(): TaskHistory[] {
    if (!this.taskHistories.length) {
      return [];
    }
    if (!this.meeting) {
      return this.taskHistories;
    }

    // Avoid editing the source.
    const result: TaskHistory[] = [...this.taskHistories];

    if (this.taskHistories[0].meeting?.id === this.meeting.id && this.taskHistories[0].action === 'create') {
      // The top item is this meeting, which created it. Remove that, we don't need that as info now.
      result.shift();
    }

    return result;
  }

}
