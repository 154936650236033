import { BaseModel } from './baseModel';
import { User } from './user';

export class Document extends BaseModel {

  id = 0;
  url = '';
  name = '';
  ext = '';
  size = 0;
  user: User = null;

  // Meeting Documents
  label = '';

  // Circle and Sphere Documents
  tab = '';

  constructor(object?: Partial<Document>) {
    super();
    this.fill(object);

    if (object && typeof object === 'object') {
      // Make the User is a real User.
      if (this.user) {
        this.user = new User(this.user);
      }
    }
  }

}
