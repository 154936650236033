<h1 matDialogTitle class="mb-2">{{ data.title || 'Are you sure?' }}</h1>
<div mat-dialog-content *ngIf="data.message">{{ data.message }}</div>
<div mat-dialog-content class="mt-2" *ngIf="data.checkbox">
  <mat-checkbox
      (change)="returnValue = $event.checked ? 2 : 1"
      [matTooltip]="data.checkbox.tooltip || null"
  >{{ data.checkbox.label }}</mat-checkbox>
</div>
<div mat-dialog-actions class="mt-2 has-spacer">
  <span
      *ngIf="data.more_info"
      [matTooltip]="data.more_info"
      #tooltip="matTooltip"
      (click)="tooltip.toggle()"
      style="flex: 1; cursor: help"
  >More info...</span>
  <span class="spacer"></span>
  <button type="button" mat-stroked-button (click)="dialogRef.close(0)">Cancel</button>
  <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(returnValue)">OK</button>
</div>
