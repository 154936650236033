<mat-form-field [matTooltip]="tooltip">
  <mat-label>{{ label }}</mat-label>
  <input
      placeholder="Name or email"
      matInput
      #userFilterInput
      [formControl]="userFilter"
      [matAutocomplete]="ac"
      (blur)="onBlur()"
  >
  <mat-error>Select someone from the list or submit an email address.</mat-error>
  <mat-icon matSuffix class="add-icon" *ngIf="mode==='add'">person_add</mat-icon>
  <mat-autocomplete
      #ac="matAutocomplete"
      (optionSelected)="optionSelected($event)"
      (closed)="userFilterInput.blur()"
      [displayWith]="displayWith"
  >
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user"><span>{{
        user.tryFullName || user.email
      }}</span><mat-icon>keyboard_return</mat-icon>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
