import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { User } from 'app/models/user';
import { isAuthenticated, loadedUserData } from 'app/common/helpers';
import { BehaviorSubject, Observable } from 'rxjs';

interface RegisterData {
  email: string;
  password: string;
  invited_email?: string;
  share_code?: string;
  partner?: string;
  first_name?: string;
  last_name?: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // To make this service available to Model classes.
  static instance: UserService;

  // The signed-in user. It is instantiated so that views can immediately initialize; the values are later updated.
  private storedUser = new User();

  // This will be set from the NotificationService. We start out with benefit of the doubt.
  emailVerified = true;

  loggedIn$ = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private http: HttpClient,
  ) {
    UserService.instance = this;
  }

  get user() {
    if (!this.storedUser.id && isAuthenticated() && loadedUserData()) {
      // No stored user yet, but we think we should be authenticated,
      // and during app startup, User data was found.
      let loadedUser: User = null;
      try {
        const obj = JSON.parse(loadedUserData());
        loadedUser = typeof obj === 'object' && obj.data ? obj.data : null;
      } catch (e) {}
      loadedUserData(null);
      if (loadedUser?.id) {
        return this.setUser(loadedUser);
      }
    }
    return this.storedUser;
  }

  private setUser(newUser) {
    if (newUser?.id !== this.storedUser?.id) {
      this.loggedIn$.next(!!newUser?.id);
    }
    return this.storedUser = new User(newUser);
  }

  clearUser() {
    this.setUser(null);
  }

  get planLevel() {
    return this.storedUser.licensePlan?.level;
  }

  // Register a new user. Optionally send along invitedEmail and partner, if they are set.
  register(data: RegisterData): Observable<User> {
    if (data.invited_email == null) {
      data.invited_email = '';
    }
    const partner = localStorage.getItem('partner') || '';
    if (partner) {
      data.partner = partner;
    }
    return this.http.post<any>('/auth/register', data)
      .pipe(map(this.handleUser));
  }

  login(email: string, password: string, remember?: boolean|string): Observable<User> {
    if (!remember) {
      // Because the string 'false' is considered a truthy value at the backend.
      remember = '';
    }
    return this.http.post<any>('/auth/login', { email, password, remember })
      .pipe(map(this.handleUser));
  }

  // Called after Register, Login and setGuestUser, who all immediately need the User for the next page.
  private handleUser = (x: {data: User}) => {
    // Mark us as authenticated (by creating a non-empty item).
    localStorage.setItem('auth', '.');
    // Just in case it was set:
    localStorage.removeItem('partner');
    localStorage.removeItem('activeMyOurView');
    // Store it.
    return this.setUser(x.data);
  }

  setGuestAuth(user: User) {
    user.is_guest = true;
    return this.handleUser({data: user});
  }

  forgotPassword(email: string) {
    return this.http.post<any>('/auth/password/email', { email });
  }

  resetPassword(token: string, email: string, password: string) {
    return this.http.post<any>('/auth/password/reset', { token, email, password, password_confirmation: password });
  }

  changePassword(old_password, new_password) {
    return this.http.put<any>('/api/user/password', { old_password, new_password });
  }

  updateProfile(data) {
    return this.http.put<any>(`/api/user`, data)
      .pipe(map(x => this.setUser(x.data)));
  }

  addEmail(email) {
    return this.http.post<any>(`/api/user/emails`, { email });
  }

  updatePicture(picture_data: string) {
    return this.http.put<any>('/api/user/picture', { picture_data });
  }

  isMe(user: User) {
    return user?.id && user.id === this.user.id;
  }

}
