import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

export interface IMenuItem {
  type: 'link' | 'dropDown' | 'icon' | 'separator' | 'extLink';
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable({ providedIn: 'root' })
export class MenuService {

  public lastPage: string;
  public searchActive$ = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
  ) {
    // Store previous page.
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        pairwise<NavigationEnd>()
      ).subscribe(
        (e: NavigationEnd[]) => this.lastPage = e[0].url
      );
    // Reset searchActive.
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart)).subscribe(() => {
      this.searchActive$.next(false);
    });
  }

  private myMenu: IMenuItem[] = [
    {
      name: 'My World',
      type: 'link',
      state: 'spheres',
    },
    {
      name: 'My Priorities',
      type: 'link',
      state: 'circles',
    },
    {
      name: 'My Meetings',
      type: 'link',
      state: 'meetings'
    },
    {
      name: 'My Planning',
      type: 'link',
      state: 'tasks'
    },
  ];

  private menuItems = new BehaviorSubject<IMenuItem[]>(this.myMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

}
