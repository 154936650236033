import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { logout } from 'app/common/helpers';
import { NavigationService } from 'app/services';

/**
 * Don't allow the 'back' (or 'forward') button to bring us to/from the login page.
 */
@Injectable()
export class NavigationGuard implements CanActivate, CanDeactivate<any> {

  constructor(
    private navigationService: NavigationService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.navigationService.hardwareNavClicked && state.url.startsWith('/login')) {
      // Don't allow navigating to the login screen by pressing 'back' just after you logged in.
      return false;
    }
    if (state.url === '/logout') {
      logout();
      return false;
    }
    return true;
  }

  async canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.navigationService.hardwareNavClicked && state.url.startsWith('/login')) {
      return false;
    }

    return true;
  }

}
