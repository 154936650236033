<div class="content container" [class.collapsed]="collapsed">
  <div>
    <p>Welcome <b>{{ user.nameFromEmail }}</b>! You are viewing this meeting via a temporary link.</p>
    <p>Create a free account to revisit it in the future.</p>
  </div>
  <div class="actions">
    <div>
      <button mat-raised-button (click)="register()">Create account</button>
      <a  (click)="login()">Already have an account? Log in.</a>
    </div>
  </div>
  <button mat-icon-button (click)="collapsed = !collapsed">
    <mat-icon class="mat-icon material-icons">{{ collapsed ? 'expand_less' : 'expand_more' }}</mat-icon>
  </button>
</div>
