import { HttpClient } from '@angular/common/http';
import { InjectorInstance } from 'app/app.module';
import { UserService } from 'app/services';
import { User } from 'app/models';

let _loadedUserData: string;
// Temporary storage for the User during App initialization.
export const loadedUserData = (par?: string) => {
  if (par !== undefined) {
    _loadedUserData = par;
  } else {
    return _loadedUserData;
  }
}

export const authUser = () => {
  return UserService.instance.user;
}
export const authId = () => {
  return authUser()?.id;
}

export const focusInput = (formControlName: string) => {
  setTimeout(() => (<HTMLElement>document.querySelector(`.mat-input-element[formControlName='${formControlName}']`))?.focus());
}

export const scrollToElement = (el: HTMLElement) => {
  if (!el) {
    return false;
  }
  const y = el.getBoundingClientRect().top + window.scrollY - 140;
  window.scrollTo({top: y, behavior: 'smooth'});
}

// Draw attention to an element with some animation.
export const growShrink = (el: HTMLElement) => {
  if (!el) {
    return false;
  }
  el.classList.add('grow', 'no-transition');
  setTimeout(() => { el.classList.remove('no-transition'); }, 1);
  setTimeout(() => { el.classList.remove('grow'); }, 500);
}

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

// Partition an array into two parts based on a filter.
export const partitionArray = (array: any[], filter: Function) => {
  let pass = [], fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
}

// Removes the object with the given id, if found. This will update the list that was sent.
export const removeById = (list: any[], id) => {
  const index = list.findIndex(x => x.id === id);
  if (index >= 0) {
    list.splice(list.findIndex(x => x.id === id), 1);
  }
  return list;
}

export const isValidEmail = (email: string): boolean => {
  return !!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

// This is not in a Service, because the AppInitializer needs it.
export const logout = (options: { keepPartner?: boolean, redirectToLogin?: boolean, redirectTo?: string } = {}) => {
  document.getElementsByTagName('body')[0].classList.add('fading')
  options = Object.assign(
    { keepPartner: false, redirectToLogin: true },
    options
  );

  // Mark app as not-logged-in (so that a refresh will not think it's still logged in, even if logout above fails).
  // Other localStorage is kept, such as active context (Sphere/Circle).
  localStorage.removeItem('auth');
  localStorage.removeItem('activeMyOurView');
  if (!options.keepPartner) {
    localStorage.removeItem('partner');
  }

  // We send logout with HttpClient, to make sure csrf token is sent.
  const http = InjectorInstance.get<HttpClient>(HttpClient);
  http.post('/auth/logout', {}).subscribe({
    next: () => {
      // We need to wait with this redirect for the logout, in case it links to an in-app path.
      if (options.redirectTo) {
        if (document.location.href === options.redirectTo) {
          document.location.reload();
        } else {
          document.location.href = options.redirectTo;
        }
      }
    },
    error: () => {
      // Logout failed? Clear everything on our side.
      deleteAllCookies();
    }
  });

  // Assuming we're logging out, we can immediately redirect to login already.
  if (options.redirectToLogin && !options.redirectTo) {
    document.location.href = '/login';
  } else {
    // We're not reloading the page? Then make sure the UserService does not still have the User.
    UserService.instance?.clearUser();
  }
}

// Are we authenticated (according to local information)?
// (The first GET /user will check whether it's still valid at the backend.)
export const isAuthenticated = (): boolean => {
  return localStorage.getItem('auth') !== null;
}

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}

export const bySort = (a: any, b: any) => a.sort < b.sort ? -1 : (a.sort > b.sort ? 1 : 0);

export const userSort = (a: User, b: User) => {
  if (b.access_level > a.access_level) return 1;
  if (a.access_level > b.access_level) return -1;
  if (b.id === authId()) return 1;
  if (a.id === authId()) return -1;
  if (!!b.response && !a.response) return 1;
  if (!!a.response && !b.response) return -1;
  if (b.name < a.name) return 1;
  if (a.name < b.name) return -1;

  return a.id > b.id ? -1 : (a.id < b.id ? 1 : 0);
}
