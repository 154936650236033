import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class HelperService {

  touching = false;
  touching$ = new BehaviorSubject(false);

  // Ticks every 5 seconds, returns unix timestamp in milliseconds.
  timer$ = new BehaviorSubject(Date.now());
  private timerInterval;

  constructor(
    private router: Router,
    private snack: MatSnackBar,
  ) {
    this.touching$.subscribe(x => this.touching = x);

    this.timerInterval = window.setInterval(() => {
      this.timer$.next(Date.now());
    }, 5000);
  }

  storePartner(route: ActivatedRouteSnapshot) {
    // Check the querystring for ?partner.
    const partner = route.queryParamMap.get('partner') || '';

    // If one of the above, store it locally.
    if (partner) {
      localStorage.setItem('partner', partner.toLowerCase().replace('_', ' '));
    }
  }

  responseLabelToCode(responseLabel: string): 0|1|2|3 {
    switch (responseLabel) {
      case 'accept': return 1;
      case 'decline': return 2;
      case 'tentative': return 3;
      default: return 0;
    }
  }

  redirectGuestBackTo(url) {
    this.snack.open(
      'You are logged in as guest and can only view this meeting. Please create an account to explore all features.',
      'OK',
      {duration: 9000}
    );
    this.router.navigate([url]);
  }

}
