import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { ConfirmComponent } from './confirm.component';

export interface ConfirmData {
  title?: string;
  message?: string;
  more_info?: string;
  checkbox?: {
    label: string;
    tooltip?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ConfirmService {

  constructor(private dialog: MatDialog) { }

  // Will return 0 on cancel, 1 on OK, and 2 on OK with the checkbox checked.
  public confirm(data: ConfirmData = {}): Observable<number> {
    let dialogRef: MatDialogRef<ConfirmComponent>;
    dialogRef = this.dialog.open(ConfirmComponent, {
      width: '360px',
      disableClose: true,
      autoFocus: false,
      data: data
    });
    return dialogRef.afterClosed();
  }

}
