<!--<div class="header-topnav" [class.dark]="'my' == (settingsRepo.activeMyOurView$ | async)">-->
<div class="header-topnav">
  <div class="container">
    <div class="topnav">
      <!-- App Logo -->
      <a routerLink="/" class="topbar-branding">
        <img src="assets/img/logo-x.png" alt="" class="app-logo">
      </a>

      <!-- Navigation -->
      <div class="menubar">
        <div class="container">
          <ul>
            <li *ngFor="let item of menuItems">
              <a
                  matRipple
                  routerLinkActive="active"
                  [routerLink]="item.disabled ? null : '/' + item.state"
                  [class.fade-out]="item.disabled"
                  [matTooltip]="item.tooltip"
                  #tooltip="matTooltip"
                  (click)="tooltip && touchForTooltip(tooltip)"
              >
                <span (click)="maybeGoBack($event)">{{ item.name }}</span>
              </a>
            </li>
          </ul>
          <div
              class="search"
              [class.active]="!!searchTermControl.value || (searchLabels$ | async)?.length"
              [class.hide-sm]="!(menuService.searchActive$ | async)"
          >
            <!-- Sphere/Circle context -->
            <mat-chip
                *ngIf="activeContextTooltip"
                [class.fade-out]="!(contextActive$ | async)"
                [matTooltip]="activeContextTooltip + ((contextActiveCanToggle$ | async) ? '\n\nClick to toggle this filter.' : '')"
                [class.clickable]="contextActiveCanToggle$ | async"
                [disabled]="!(contextActiveCanToggle$ | async)"
                (click)="contextActiveCanToggle$.value ? toggleContextActive() : null"
            >{{ activeContext }}</mat-chip>
            <!-- Selected labels and search term -->
            <ng-container *ngIf="menuService.searchActive$ | async">
              <mat-chip *ngFor="let label of searchLabels$ | async">{{ label }}</mat-chip> <!-- this never happens -->
              <mat-form-field appearance="outline">
                <input matInput placeholder="Search meetings..." [formControl]="searchTermControl">
                <mat-icon matSuffix *ngIf="searchTermControl.value" (click)="searchTermControl.setValue(''); setSearchTerm('')">clear</mat-icon>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- My / Our / Their View -->
      <ul class="our_my">
        <li><a class="active" style="cursor: default"><span>My View</span></a></li>
        <li><a class="fade-out" style="cursor: default" matTooltip="Get in touch for the creation of team and company dashboards."><span>Our View</span></a></li>
        <li><a class="fade-out" style="cursor: default" matTooltip="If you are an advisor working for several clients, get in touch to become part of the Flxion advisory community and access your clients' Flxion accounts from this page."><span>Their View</span></a></li>
      </ul>

      <span></span>

      <!-- Notification toggle button -->
      <button
          *ngIf="!user.is_guest"
          mat-icon-button
          matTooltip="Notifications"
          (click)="toggleNotification()"
          [style.overflow]="'visible'"
          class="topbar-button-right"
      >
        <mat-icon>notifications</mat-icon>
        <span *ngIf="countUnreadNotifications() as count" class="notification-number mat-bg-warn">{{ count }}</span>
      </button>

      <!-- Top right user menu -->
      <button mat-icon-button [matMenuTriggerFor]="accountMenu" style="width: auto; height: auto; margin-left: 10px;">
        <app-user-avatar [user]="user" style="width: 38px; line-height: initial;"></app-user-avatar>
      </button>
      <mat-menu #accountMenu="matMenu" class="profile-menu">
        <button *ngIf="user.is_guest" mat-menu-item (click)="register()">
          <mat-icon>account_box</mat-icon>
          <span>Create account</span>
        </button>
        <button *ngIf="!user.is_guest" mat-menu-item routerLink="/profile">
          <mat-icon>account_box</mat-icon>
          <span>Profile</span>
        </button>
        <button *ngIf="!user.is_guest" mat-menu-item routerLink="/account/settings">
          <mat-icon>settings</mat-icon>
          <span>Settings</span>
        </button>
        <button mat-menu-item routerLink="/logout">
          <mat-icon>exit_to_app</mat-icon>
          <span>Log out</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
