import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { isValidEmail } from '../helpers';
import { UsersRepository } from 'app/repositories';
import { User } from 'app/models';

@Component({
  selector: 'flxion-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  @Input() mode: 'add'|'select' = 'add';
  @Input() tooltip: string = null;
  @Input() initialValue: string = '';
  @Input() label: string;
  @Input() skipUserIds: number[] = [];
  @Output() onSelect = new EventEmitter<User>();

  knownUsers: User[] = [];
  userFilter = new FormControl();
  filteredUsers: Observable<User[]>;

  constructor(
    private usersRepo: UsersRepository,
  ) {}

  ngOnInit() {
    this.usersRepo.users$.subscribe(data => this.knownUsers = data);
    this.userFilter.setValue(this.initialValue);
    this.initFilter();
  }

  onBlur() {
    this.userFilter.setErrors(this.userFilter.value && !(this.userFilter.value instanceof User) ? {e: 1} : null);
    if (!this.userFilter.value && this.mode === 'select') {
      this.onSelect.emit(null);
    }
  }

  private initFilter() {
    this.filteredUsers = this.userFilter.valueChanges
      .pipe(
        startWith(this.userFilter.value as string || ''),
        map(value => this.filterUsers(value))
      );
  }

  // Filter the list of known users by the typed in value.
  private filterUsers(value: string): User[] {
    if (typeof value !== 'string') {
      // On selecting an option, value is a User and will be immediately cleared, so just return.
      return;
    }

    // Only show those that aren't already in this meeting's list.
    if (value === '') {
      return []; // selectableUsers;
    }

    // Partial match of name, or the beginning of an email address if user has no name.
    const lc = value.trim().toLowerCase();
    const filtered = this.knownUsers.filter(
      user => !this.skipUserIds.includes(user.id)
        && (user.name.toLowerCase().includes(lc) || (!user.name && user.email.toLowerCase().startsWith(lc)))
    );
    if (filtered) {
      if (filtered.length) {
        return filtered;
      } else if (lc) {
        // No match. Is it an email address? Make it selectable.
        if (isValidEmail(lc)) {
          // Create a 'fake' user with just that email, and return it as a selectable match.
          return [new User({email: lc})];
        }
      }
    }
    return [];
  }

  // A filtered value was selected.
  optionSelected(event) {
    // Send it to the parent.
    this.onSelect.emit(event.option.value as User);
    if (this.mode === 'add') {
      // Reset filter.
      this.userFilter.setValue('');
    }
  }

  displayWith(value) {
    return value instanceof User ? value.name : value;
  }

}
