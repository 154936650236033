import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { MeetingService, UserService } from 'app/services';
import { logout } from 'app/common/helpers';

@Component({
  selector: 'flxion-guest-banner',
  templateUrl: './guest-banner.component.html',
  styleUrls: ['guest-banner.component.scss']
})
export class GuestBannerComponent {

  collapsed = false;

  get user() {
    return this.userService.user;
  }

  constructor(
    private location: Location,
    private userService: UserService,
    private meetingService: MeetingService,
  ) { }

  login() {
    // Log out of the guest session first, then move to the login.
    logout({
      redirectTo: '/login?returnUrl=' + this.location.path()
        + (this.meetingService.storedToken ? '&returnUrlHash=' + this.meetingService.storedToken : '')
    });
  }

  register() {
    // Reload in order to make sure services will be restarted.
    location.href = '/register?returnUrl=' + this.location.path() + '&email=' + encodeURIComponent(this.user.email);
  }

}
