<form (submit)="save($event)">
  <h1 matDialogTitle>{{ oldSwimlaneId ? 'Change' : 'Assign to' }} swimlane</h1>

  <div matDialogContent>
    <mat-form-field>
      <mat-label>Choose a swimlane</mat-label>
      <mat-select (selectionChange)="optionSelected($event)" [formControl]="formControl">
        <mat-option *ngFor="let swimlane of swimlaneOptions" [value]="swimlane">{{ swimlane.title }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div matDialogActions>
    <button *ngIf="dirty" mat-raised-button color="primary">Save</button>
    <button *ngIf="!dirty" mat-stroked-button>Close</button>
  </div>
</form>
