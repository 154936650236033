import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { ThemePalette } from '@angular/material/core';

export type SnackbarAction = {
  label: string;
  color?: ThemePalette;
  handler?: () => void;
}
export type SnackbarData = {
  message: string;
  actions: [SnackbarAction]
}

/**
 * A Snackbar that can have multiple actions.
 */
@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
})
export class SnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData,
    public snackBar: MatSnackBar
  ) {}

  handle(action: SnackbarAction) {
    if (action.handler) {
      action.handler();
    }
    this.snackBar.dismiss();
  }

}
