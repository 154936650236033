<p [ngClass]="users?.length > 7 ? (users.length > 10 ? 'tons-of-users' : 'many-users') : ''">
  <mat-icon class="text-muted">group</mat-icon>
  <span class="user-avatar" *ngFor="let user of users" [class.editor]="user.access_level >= 2">
    <app-user-avatar [user]="user" [showTooltip]="true"></app-user-avatar>
    <span
        *ngIf="showResponses"
        class="response"
        [class]="user.responseClass"
        [matTooltip]="user.responseTooltip"
    ><mat-icon *ngIf="user.response != 3; else tentative">{{ user.responseIcon }}</mat-icon
    ><ng-template #tentative><span>?</span></ng-template></span>
  </span>
  <span class="users_loading" *ngIf="users === null"></span>
</p>
