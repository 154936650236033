<form (ngSubmit)="save()" [formGroup]="form">
  <h1 matDialogTitle>{{ user.name }}<span class="text-muted" *ngIf="!user.id">({{ user.email }})</span></h1>

  <div matDialogContent>
    <mat-form-field>
      <mat-label>Role</mat-label>
      <input
          matInput
          formControlName="label"
      >
    </mat-form-field>
    <mat-form-field *ngIf="form.controls.access_level">
      <mat-label>Access level</mat-label>
      <mat-select formControlName="access_level">
        <mat-option [value]="2">Co-editor</mat-option>
        <mat-option [value]="1">Participant</mat-option>
        <mat-option [value]="0">Visitor</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div matDialogActions>
    <button mat-stroked-button color="warn" type="button" (click)="delete()">Delete</button>
    <button *ngIf="form.dirty" mat-raised-button color="primary">Save</button>
    <button *ngIf="!form.dirty" mat-stroked-button>Close</button>
  </div>
</form>
