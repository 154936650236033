import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { MemberDetailsComponent } from './member-details/member-details.component';
import { Circle, Sphere, User } from 'app/models';
import { CirclesRepository, SpheresRepository } from 'app/repositories';

@Component({
  selector: 'flxion-people-tab',
  templateUrl: './people-tab.component.html',
  styleUrls: ['./people-tab.component.scss']
})
export class PeopleTabComponent implements OnInit {

  @Input() canWrite: boolean;
  @Input() object$: BehaviorSubject<Circle | Sphere>;
  @Input() repo: CirclesRepository | SpheresRepository;
  @Output() saving = new EventEmitter<number>();

  private object: Circle | Sphere;

  membersTitle = 'Members';
  visitorsTitle = 'Visitors';
  visitorsHint = '';
  isMember = false;

  members: User[] = [];
  visitors: User[] = [];

  skipUserIds: number[] = [];

  constructor(
    private dialog: MatDialog,
    private snack: MatSnackBar,
  ) {}

  ngOnInit() {
    this.object$.subscribe(obj => {
      this.object = obj;

      this.membersTitle = this.object?.constructor.name === 'Sphere' ? 'Colleagues' : 'Participants';
      this.visitorsTitle = this.object?.constructor.name === 'Circle' ? 'Invitees' : 'Guests';
      this.visitorsHint = this.object?.constructor.name === 'Circle'
        ? 'When people from outside this circle are invited to meetings in this circle, they will appear here.'
        : 'When people from outside this sphere are added to circles inside this sphere, they will appear here.';

      this.afterUsersUpdate();
    });
  }

  private afterUsersUpdate() {
    this.members = this.object?.users.filter(u => u.access_level > 0);
    this.visitors = this.object?.users.filter(u => u.access_level === 0);

    this.isMember = this.members?.some(u => u.isMe);

    this.skipUserIds = this.object?.users.map(user => user.id);
  }

  toggleEditingRights(user: User) {
    user.access_level = user.access_level === 2 ? 1 : 2;
    const newList = [...this.object.users];
    this.object.setUsers(newList);
    this.repo.updateUsers(this.object.id, newList).subscribe();
  }

  openPopUp(user: User) {
    this.dialog.open(MemberDetailsComponent, {
      width: '480px',
      disableClose: true,
      data: user
    }).afterClosed().subscribe((data: {label?: string, access_level?: 0 | 1 | 2, delete?: true }) => {
      if (!data) {
        return;
      } else if (data.delete) {
        this.removeUser(user);
        return;
      }
      Object.assign(user, data);
      // Remove previous version from list and re-add at the end.
      const newList = [
        ...(user.id
          ? this.object.users.filter(x => x.id !== user.id)
          : this.object.users.filter(x => x.id || x.email.toUpperCase() !== user.email.toUpperCase())),
        user
      ];
      this.object.setUsers(newList);
      this.repo.updateUsers(this.object.id, newList).subscribe();
    });
  }

  private removeUser(user: User) {
    const newList = user.id
      ? this.object.users.filter(x => x.id !== user.id)
      : this.object.users.filter(x => x.id || x.email.toUpperCase() !== user.email.toUpperCase());
    this.object.setUsers(newList);
    this.repo.updateUsers(this.object.id, newList).subscribe();
    this.snack.open(user.name + ' has been removed from the list.', null, {duration: 3000});
  }

  // A filtered value was selected.
  addUser(user: User) {
    // Add selected user to list
    if (!this.userInList(user)) {
      if (!user.id && user.first_name === user.email) {
        user.first_name = '';
      }
      this.object.users.push(user);
      this.object.setUsers(this.object.users);
      this.updateUsers();
    } else {
      this.snack.open('That user is already in the list.', null, {duration: 3000});
    }
  }

  // Check whether the given User is already in the list of Users.
  private userInList(user: User) {
    return this.object?.users.some(x => {
      if (user.id && x.id === user.id || x.email.toUpperCase() === user.email.toUpperCase()) {
        return true;
      }
    });
  }

  private updateUsers() {
    if (!this.object.id) {
      this.saving.emit(0);
      return;
    }
    this.saving.emit(Date.now());

    const users = this.object.users;
    this.repo.updateUsers(this.object.id, users).subscribe(
      users => {
        this.object.setUsers(users);
        this.afterUsersUpdate();
        this.saving.emit(0);
      }
    );
  }

}
