import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import Quill from 'quill';

import { CirclesRepository, MeetingsRepository, SpheresRepository } from './repositories';
import { HelperService, NavigationService, UserService } from './services';

@Component({
  selector: 'flxion-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  appTitle = 'Flxion';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private helperService: HelperService,
    private location: LocationStrategy,
    private navigationService: NavigationService,
    private userService: UserService,
    private circlesRepo: CirclesRepository,
    private spheresRepo: SpheresRepository,
    private meetingsRepo: MeetingsRepository,
  ) {
    this.loadSomeData();
  }

  ngOnInit() {
    this.initQuill();
    this.watchPageTitle();
    this.detectTouchingDevice();
    this.detectHardwareNavButtons();
  }

  // Load the Spheres, Circles and Meetings.
  private loadSomeData() {
    this.userService.loggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        // Note: these loadAll queries are not returning sanitized objects.
        combineLatest([
          this.spheresRepo.loadAll(),
          this.circlesRepo.loadAll(),
          this.meetingsRepo.loadAll(), // Returns as soon as we have the basic info, without users or tasks_completion.
        ]).subscribe();
      } else {
        this.spheresRepo.clearAll();
        this.circlesRepo.clearAll();
      }
    });
  }

  private watchPageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const routeParts = this.navigationService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }
      // Extract title from parts.
      this.pageTitle = routeParts
        .reverse()
        .map((part) => part.title )
        .reduce((partA, partI) => `${partA} > ${partI}`);
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  private detectTouchingDevice() {
    const onFirstTouch = () => {
      this.helperService.touching$.next(true);
      window.removeEventListener('touchstart', onFirstTouch, false);
    }
    window.addEventListener('touchstart', onFirstTouch, false);
  }

  private detectHardwareNavButtons() {
    this.location.onPopState(() => {
      // Back (or forward) button used.
      this.navigationService.setHardwareNavClicked();
      // If a modal was open, close it, instead of navigating to a real previous page.
      // this.modalCtrl.getTop().then(x => {
      //   if (x) {
      //     this.modalCtrl.dismiss();
      //     this.navigationService.setPreventDeactivate();
      //   }
      // });
    });
  }

  private initQuill() {
    // Load extra icons for Quill Editor toolbar (taken from quill/assets/icons).
    const icons = Quill.import('ui/icons');
    icons['undo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>' +
      '<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>';
    icons['redo'] = '<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>' +
      '<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>';
    icons['header']['3'] = '<svg viewBox="0 0 18 18"><path class="ql-fill" d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"/></svg>';
  }

}
