import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Room } from 'app/models';

export interface Category { id: number, order: number, name: string }

const DefaultCategories: Category[] = [
  { id: 1, order: 0, name: 'Category' },
  { id: 2, order: 2, name: 'Red' },
  { id: 3, order: 0, name: 'Category' },
  { id: 4, order: 0, name: 'Category' },
  { id: 5, order: 0, name: 'Category' },
  { id: 6, order: 4, name: 'Yellow' },
  { id: 7, order: 0, name: 'Category' },
  { id: 8, order: 0, name: 'Category' },
  { id: 9, order: 0, name: 'Category' },
  { id: 10, order: 0, name: 'Category' },
  { id: 11, order: 1, name: 'Green' },
  { id: 12, order: 0, name: 'Category' },
  { id: 13, order: 0, name: 'Category' },
  { id: 14, order: 0, name: 'Category' },
  { id: 15, order: 3, name: 'Blue' },
  { id: 16, order: 0, name: 'Category' },
]

interface SettingsCache {
  labels: string[],
  categories: Category[],
  rooms: Room[],
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private cache: SettingsCache = {
    labels: null,
    categories: null,
    rooms: null,
  }

  constructor(
    private http: HttpClient
  ) { }

  getCategories() {
    if (this.cache.categories !== null) {
      return of(this.cache.categories);
    }
    return this.http.get<any>(`/api/categories`)
      .pipe(map<any, Category[]>(x => this.cache.categories = x.data || DefaultCategories));
  }

  updateCategories(categories: Category[]) {
    return this.http.put<any>(`/api/categories`, categories)
      .pipe(map<any, Category[]>(x => this.cache.categories = x.data));
  }

  // Get all labels that this user has used before.
  getKnownLabels() {
    if (this.cache.labels !== null) {
      return of(this.cache.labels);
    }
    return this.http.get<any>(`/api/labels`)
      .pipe(map(x => this.cache.labels = x.data as string[]));
  }

  clearLabelsCache() {
    this.cache.labels = null;
  }

  getRooms() {
    if (this.cache.rooms !== null) {
      return of(Room.parseObjects(this.cache.rooms));
    }
    return this.http.get<any>(`/api/rooms`)
      .pipe(
        map<any, Room[]>(x => {
          this.cache.rooms = Room.parseObjects(x.data) || [];
          // this.cache.rooms.unshift(new Room({
          //   id: 1,
          //   title: 'Flxion video room',
          //   video_link: 'https://videoroom.flxion.com',
          //   in_my_list: false,
          //   in_other_list: true, // Make it not editable.
          // }));
          return Room.parseObjects(this.cache.rooms);
        }),
      );
  }

  addRoom(room: Room) {
    return this.http.post<{ data: Room }>(`/api/rooms`, room)
      .pipe(map(x => {
        if (x) {
          const room = new Room(x.data);
          this.cache.rooms.push(room);
          return room;
        }
        return null;
      }));
  }

  updateRoom(room: Room) {
    return this.http.put<{ data: Room }>(`/api/rooms/${room.id}`, room)
      .pipe(map(x => {
        if (x) {
          const room = new Room(x.data)
          const index = this.cache.rooms.findIndex(y => y.id === room.id);
          if (index >= 0) {
            this.cache.rooms[index] = room;
          } else if (room.in_my_list) {
            this.cache.rooms.push(room);
          }
          return room;
        }
        return null;
      }));
  }

  deleteRoom(room: Room) {
    return this.http.delete<Room>(`/api/rooms/${room.id}`)
      .pipe(map(() => {
        const index = this.cache.rooms.findIndex(y => y.id === room.id);
        if (index >= 0) {
          this.cache.rooms.splice(index, 1);
        }
        return this.cache.rooms;
      }));
  }

}
