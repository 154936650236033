import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

import { Swimlane } from 'app/models';

@Component({
  selector: 'flxion-change-swimlane',
  templateUrl: './change-swimlane.component.html',
})
export class ChangeSwimlaneComponent implements OnInit, OnDestroy {

  private stopper = new Subject<void>();

  formControl: FormControl;
  swimlaneOptions: Swimlane[];
  oldSwimlaneId = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, swimlaneId: number, swimlanes: Swimlane[] },
    private dialogRef: MatDialogRef<ChangeSwimlaneComponent>,
  ) { }

  ngOnInit() {
    this.oldSwimlaneId = this.data.swimlaneId;

    this.formControl = new FormControl(this.data.swimlanes?.find(sw => sw.id === this.data.swimlaneId));
    this.swimlaneOptions = this.data.swimlanes;
  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

  get dirty() {
    return this.oldSwimlaneId !== this.data.swimlaneId;
  }

  optionSelected(event) {
    this.data.swimlaneId = event.value ? +(event.value as Swimlane).id : 0;
  }

  save(e: SubmitEvent) {
    e.preventDefault();
    if (this.dirty) {
      this.dialogRef.close({ swimlaneId: this.data.swimlaneId });
    } else {
      this.dialogRef.close(null);
    }
  }

}
