import { Document } from './document';
import { ModelWithUsers } from './modelWithUsers';
import { Sphere } from './sphere';
import { Swimlane } from './swimlane';
import { bySort } from 'app/common/helpers';
import { SpheresRepository } from 'app/repositories/spheres.repository';

export class Circle extends ModelWithUsers {

  title = '';
  code = '';

  mission = '';
  targets = '';

  documents: Document[] = [];

  // Swimlanes for Meeting Stacks.
  swimlanes: Swimlane[] = null;

  // Fields depending on the User who is viewing.
  swimlane_id = 0;
  sort_in_swimlane = 0;
  my_swimlane_id = 0;
  sort_in_my_swimlane = 0;

  num_meetings: number = null;
  all_meetings_loaded = false; // Set to true when Meetings I'm not in are loaded.

  private _sphere: Sphere;
  private _sphere_id = 0;

  constructor(object?: Partial<Circle>) {
    super(object);
    this.fill(object);

    if (object && typeof object === 'object') {
      if (object.sphere_id) {
        this.sphere_id = object.sphere_id;
      } else {
        this.sphere_id = -1;
      }

      // Make the Document list into real Document objects.
      if (this.documents.length) {
        this.documents = Document.parseObjects(this.documents);
      }
      // Make the new Swimlane list into real Swimlane objects.
      if (this.swimlanes?.length) {
        this.swimlanes = Swimlane.parseObjects(this.swimlanes);
        this.swimlanes.sort(bySort);
      } else {
        this.swimlanes = null;
      }
    }
  }

  get trimmedDescription() {
    return this.mission?.replace('<p></p>', '').replace('<p><br></p>', '');
  }

  get sphere(): Sphere {
    if (this._sphere) {
      return this._sphere;
    }
    return this._sphere = SpheresRepository.instance.spheres.find(sphere => sphere.id === this.sphere_id);
  }

  get sphere_id(): number {
    return this._sphere_id;
  }

  set sphere_id(id: number) {
    this._sphere_id = id;
    this._sphere = undefined;
  }

  getSphereCode(): string {
    return this.sphere?.code || this.sphere?.title || '__';
  }

  /**
   *  Return the Circle's Sphere prefix, either its Code or Title, including the › sign after it.
   */
  getSpherePrefix(): string {
    return this.getSphereCode() + ' › ';
  }

  getSphereTooltip(): string {
    return 'Sphere:  ' + (this.sphere?.title || 'No sphere assigned yet.');
  }

}
