import { BaseModel } from './baseModel';
import { User } from './user';
import { Meeting } from './meeting';

export class TaskHistory extends BaseModel {

  id = 0;
  data: Map<string, any> = null;
  action = '';
  created_at = '';
  // chapter = ''; // deprecated
  meeting: Meeting = null;
  user: User = null;

  constructor(object?: Partial<TaskHistory>) {
    super();
    this.fill(object);

    if (object && typeof object === 'object') {
      // Make the User into a real User object.
      if (this.user) {
        this.user = new User(this.user);
      }

      // Make the Meeting into a real Meeting object.
      if (this.meeting) {
        this.meeting = new Meeting(this.meeting);
      }

      // Make the data into a Map.
      if (typeof this.data === 'object' && this.data) {
        this.data = new Map<string, any>((<any>Object).entries(this.data));
      }
    }
  }

  get createdAtDate(): Date {
    return new Date(this.created_at);
  }

  get statusNice(): string {
    switch (this.data?.get('status')) {
      case 'todo': return 'to do';
      case 'done': return 'done';
      case 'todiscuss': return 'to discuss';
      case 'wontdo': return "won't do";
      default: return null;
    }
  }

}
