import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'flxion-confirm',
  templateUrl: 'confirm.component.html',
  styles: [`.mat-dialog-actions {margin-bottom: -12px;}`]
})
export class ConfirmComponent {

  returnValue = 1;

  constructor(
    public dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

}
