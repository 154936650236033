<!-- Header for top navigation layout -->
<flxion-header-top [notificationPanel]="notificationPanel"></flxion-header-top>
<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="content container">
      <!-- View Loader -->
      <div class="view-loader" *ngIf="isModuleLoading">
        <div class="spinner">
          <div class="double-bounce1 mat-bg-accent"></div>
          <div class="double-bounce2 mat-bg-primary"></div>
        </div>
      </div>
      <!-- View outlet -->
      <router-outlet></router-outlet>
    </div>
    <flxion-footer></flxion-footer>
  </mat-sidenav-content>

  <!-- Notification bar -->
  <mat-sidenav #notificationPanel mode="over" class="" position="end" fixedInViewport>
    <div class="nofication-panel">
      <flxion-notifications [notificPanel]="notificationPanel"></flxion-notifications>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<!-- Tooltip holder -->
<div id="tooltip" role="tooltip" class="mat-elevation-z20 hidden"></div>

<!-- Guest Banner -->
<flxion-guest-banner *ngIf="user.is_guest"></flxion-guest-banner>
