import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationError, ResolveEnd, ResolveStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { TraceClassDecorator } from '@sentry/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UserService } from 'app/services';

@Component({
  selector: 'flxion-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['admin-layout.component.scss']
})
@TraceClassDecorator()
export class AdminLayoutComponent implements OnInit, OnDestroy {

  private body: HTMLElement;
  private stopper = new Subject<void>();

  isModuleLoading = false;
  adminContainerClasses: any = {};

  get user() {
    return this.userService.user;
  }

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
    this.body = <HTMLElement>document.querySelector('body');
    window.addEventListener('scroll', ($event: any) => {
      this.body.classList.toggle('scrolled', $event.target.scrollingElement.scrollTop > 45);
    });
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.stopper)).subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd || event instanceof NavigationError) {
        this.isModuleLoading = false;
      }
    });
  }

  ngOnDestroy() {
    this.stopper.next();
    this.stopper.complete();
  }

}
