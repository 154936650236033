import { BaseModel } from './baseModel';

export class Room extends BaseModel {

  id = 0;
  title = '';
  video_link = '';
  in_my_list = true;
  in_other_list = false;

  get mySingleUse() {
    return !this.in_my_list && !this.in_other_list;
  }

  constructor(object?: Partial<Room>) {
    super();
    this.fill(object);
  }

}
