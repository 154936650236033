import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill';

import { SharedMaterialModule } from './shared-material.module';
import { DatedPipe, FileSizePipe, InitialsPipe, MomentPipe } from 'app/pipes';

import { AddressComponent } from './address/address.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ButtonLoadingComponent } from './button-loading/button-loading.component';
import { CardCollapseDirective } from './card-collapse.directive';
import { CardTabComponent } from './card-tab/card-tab.component';
import { ChangeSwimlaneComponent } from './change-swimlane/change-swimlane.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { DocumentsComponent } from './documents/documents.component';
import { DocumentDetailsComponent } from './documents/document-details.component';
import { DragDropFilesDirective } from './drag-drop-files.directive';
import { FooterComponent } from './footer/footer.component';
import { GuestBannerComponent } from './layouts/admin-layout/guest-banner.component';
import { HeaderTopComponent } from './header-top/header-top.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { MemberDetailsComponent } from './people-tab/member-details/member-details.component';
import { PasswordFieldComponent } from './password-field/password-field.component';
import { PeopleTabComponent } from './people-tab/people-tab.component';
import { SavedStatusComponent } from './saved-status/saved-status.component';
import { SettingsPipe } from '../pipes/settings.pipe';
import { SnackbarComponent } from '../notifications/snackbar.component';
import { SwimlaneComponent } from './swimlane/swimlane.component';
import { SwimlaneDetailsComponent } from './swimlane/swimlane-details.component';
import { TasksCompletionComponent } from './tasks-completion/tasks-completion.component';
import { TimeInputDirective } from './time-input';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { UsersOnCardComponent } from './users-on-card/users-on-card.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    RouterModule,
    QuillModule,
  ],
  declarations: [
    // Components (use in template).
    AddressComponent,
    AdminLayoutComponent,
    AppLoaderComponent,
    AuthLayoutComponent,
    ButtonLoadingComponent,
    CardTabComponent,
    DocumentsComponent,
    FooterComponent,
    GuestBannerComponent,
    HeaderTopComponent,
    NotificationsComponent,
    PasswordFieldComponent,
    PeopleTabComponent,
    SavedStatusComponent,
    SnackbarComponent,
    SwimlaneComponent,
    TasksCompletionComponent,
    TitleBarComponent,
    UserAvatarComponent,
    UserSelectComponent,
    UsersOnCardComponent,

    // Components (use as dialog).
    ChangeSwimlaneComponent,
    ConfirmComponent,
    DocumentDetailsComponent,
    MemberDetailsComponent,
    SwimlaneDetailsComponent,

    // Directives.
    CardCollapseDirective,
    DragDropFilesDirective,
    TimeInputDirective,

    // Pipes.
    DatedPipe,
    FileSizePipe,
    InitialsPipe,
    MomentPipe,
    SettingsPipe,
  ],
  exports: [
    // Components (use in template).
    AddressComponent,
    CardTabComponent,
    DocumentsComponent,
    PasswordFieldComponent,
    PeopleTabComponent,
    SavedStatusComponent,
    TasksCompletionComponent,
    TitleBarComponent,
    UserAvatarComponent,
    UserSelectComponent,
    UsersOnCardComponent,
    NotificationsComponent,
    SnackbarComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ButtonLoadingComponent,
    GuestBannerComponent,

    // Directives.
    CardCollapseDirective,
    DragDropFilesDirective,
    TimeInputDirective,

    // Pipes.
    DatedPipe,
    FileSizePipe,
    InitialsPipe,
    MomentPipe,
    SettingsPipe,
    SwimlaneComponent,
  ]
})
export class FlxionCommonModule { }
