import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { Meeting, User } from 'app/models';

@Component({
  selector: 'flxion-change-owner',
  templateUrl: './change-owner.component.html'
})
export class ChangeOwnerComponent implements OnInit {

  userFilter = new FormControl();
  selectableUsers: User[];
  filteredUsers: Observable<User[]>;
  newOwner: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public meeting: Meeting,
    public dialogRef: MatDialogRef<ChangeOwnerComponent>,
  ) {}

  ngOnInit() {
    const owner = this.meeting.owner;
    if (owner) {
      this.userFilter.setValue(owner.name);
    }

    this.selectableUsers = this.meeting.users.filter(x => x.access_level > 0 && x.response === 1);
    this.filteredUsers = this.userFilter.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterUsers(value))
      );
  }

  submit() {
    this.dialogRef.close(this.newOwner);
  }

  // Filter the list of known users by the typed in value.
  private filterUsers(value: string): User[] {
    if (typeof value !== 'string') {
      // On selecting an option, value is a User and will be immediately cleared, so just return.
      return;
    }
    // Partial match of name, or full match of email.
    const lc = value.trim().toLowerCase();
    const filtered = this.selectableUsers.filter(
      user => user.name.toLowerCase().includes(lc) || user.email.toLowerCase() === lc
    );
    if (filtered?.length) {
      return filtered;
    }
    return [];
  }

  // Mark the selected user as new owner.
  assignedSelected($event) {
    this.newOwner = $event.option.value;
    this.userFilter.setValue(this.newOwner.name);
  }

}
