import { BaseModel } from './baseModel';

export class Address extends BaseModel {

  company_name = '';
  vat_number = '';
  country_code = '';
  street = '';
  postal_code = '';
  city = '';
  vat_rate = 0;

  constructor(object?: Partial<Address>) {
    super();
    this.fill(object);
  }

}
