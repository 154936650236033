import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { mergeMap, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { PlannerMode, TaskDetailsData } from 'app/models/types';
import { Task, TaskHistory, TaskStatus } from 'app/models';
import { TasksTableComponent } from 'app/modules/tasks-table/tasks-table.component';
import { TaskDetailsComponent } from 'app/modules/tasks-table/details/task-details.component';
import { ConfirmService } from 'app/common/confirm/confirm.service';

export type TasksMode = 'meeting' | 'backlog' | 'overdue' | 'closed';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  taskSaved$ = new Subject<Task>();
  taskDeleted$ = new Subject<number>();

  // Stores TaskTables, in case they need to find each other.
  tables = new Map<string, TasksTableComponent>();

  private dialogRef: MatDialogRef<TaskDetailsComponent>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private confirmService: ConfirmService,
  ) { }

  getAll() {
    return this.http.get<any>(`/api/tasks`)
      .pipe(
        map(x => Task.parseObjects(x.data))
      );
  }

  store(task: Task) {
    return this.http.post<any>(`/api/tasks`, this.sanitize(task))
      .pipe(map(this.handleSave));
  }

  update(id: number, task: Task) {
    return this.http.put<any>(`/api/tasks/${id}`, this.sanitize(task))
      .pipe(map(this.handleSave));
  }

  updatePlanning(id: number, data: { estimated_hours: number; plan_mode: PlannerMode; planned_date: any }) {
    data.planned_date = moment(data.planned_date)?.toISOString() || null;

    return this.http.put<any>(`/api/tasks/${id}/planning`, data)
      .pipe(map(this.handleSave));
  }

  delete(id: number) {
    if (!id) {
      return of(false);
    }
    return this.http.delete(`/api/tasks/${id}`)
      .pipe(map(() => {
        this.taskDeleted$.next(id);
        return true;
      }));
  }

  private handleSave = (x: any) => {
    const task = new Task(x.data);
    this.taskSaved$.next(task);
    return task;
  }

  // Currently not used anymore.
  sortTasks(mode: TasksMode) {
    const ids = this.tables.get(mode).tasks.map(task => task.id);
    return this.http.put<any>(`/api/tasks/sort`, { ids });
  }

  unsortTask(id: number) {
    return this.http.post<any>(`/api/tasks/${id}/unsort`, {});
  }

  // Called before saving to backend.
  sanitize(task: any): Task {
    if (task.deadline && typeof task.deadline !== 'string') {
      // Convert Moment date to ISO date format
      task.deadline = task.deadline.format('YYYY-MM-DD');
    }
    delete task.creator_meeting;
    return task;
  }

  // Get TaskHistories (creation, status update, ...)
  getHistory(id: number): Observable<TaskHistory[]> {
    return this.http.get<any>(`/api/tasks/${id}/history`)
      .pipe(
        map(x => TaskHistory.parseObjects(x.data))
      );
  }

  // Add or update a Task's status update.
  // If status change, the Task itself is updated from the Component (after success here), so no need to call updatedTask$ here.
  saveStatusUpdate(taskId: number, comment: string, status: TaskStatus|null, histId: number) {
    const data: any = {comment};
    if (status !== null) {
      // If it's null, we will not update it to anything.
      data.status = status;
    }

    const call = histId
      // Update
      ? this.http.put<any>(`/api/tasks/${taskId}/history/${histId}`, data)
      // Add
      : this.http.post<any>(`/api/tasks/${taskId}/history`, data);

    return call.pipe(
      map(x => new TaskHistory(x))
    );
  }

  openPopup(data: TaskDetailsData, extraOptions: Partial<MatDialogConfig<TaskDetailsData>> = {}, finallyCallback: Function = ()=>{}) {
    const options: MatDialogConfig<TaskDetailsData> = Object.assign({
      data,
      autoFocus: false,
      disableClose: data.task.assignedToMe,
      panelClass: 'task-dialog'
    }, extraOptions);

    this.dialogRef = this.dialog.open(TaskDetailsComponent, options);
    this.dialogRef.afterClosed().pipe(
      mergeMap((x?: {deleteId: number}) => {
        this.dialogRef = null;
        if (!x?.deleteId) {
          return of(false);
        }
        return this.confirmService.confirm({message: `This will permanently delete the task.`})
          .pipe(
            mergeMap(confirmed => {
              if (!confirmed) {
                return of(false);
              }
              return this.delete(x.deleteId);
            })
          );
      }),
    ).subscribe({ complete: () => finallyCallback() });
  }

  updateTaskInPopup(task: Task) {
    this.dialogRef?.componentInstance.task$.next(task);
  }

}
