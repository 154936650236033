import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpBaseUrlInterceptor implements HttpInterceptor {
  // private backendPaths = [
  //   '/home',
  //   '/admin',
  //   '/sanctum/csrf-cookie',
  //   '/api',
  //   '/auth',
  //   '/invoices',
  //   '/videoroom',
  //   '/meeting-report',
  //   '/ical',
  //   '/vatcalculator',
  //   '/stats',
  //   '/licenses',
  //   '/css',
  //   '/js',
  // ];

  intercept(
      req: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the request URL matches one of the API paths
    // const matchesApiPath = this.backendPaths.some((path) =>
    //     req.url.startsWith(path)
    // );
    //
    // if (matchesApiPath) {
    //   //
    //   location.href = `${environment.API_DOMAIN}${req.url}`;
    //   return next.handle(req);
    // }

    // Prepend base URL if the request URL is relative
    if (!req.url.startsWith('http')) {
      const apiReq = req.clone({
        withCredentials: true,
        url: `${environment.API_DOMAIN}${req.url}`,
      });
      return next.handle(apiReq);
    }
    return next.handle(req);
  }
}
