import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Document } from 'app/models';

export abstract class DocumentService {

  protected abstract endpointName: string;
  protected abstract http: HttpClient;

  updateDocument(parentId: number, docId: number, doc: Document) {
    const data = { name: doc.name };
    return this.http.put<any>(`/api/${this.endpointName}/${parentId}/documents/${docId}`, data)
      .pipe(
        map(x => new Document(x.data))
      );
  }

  deleteDocument(parentId: number, docId: number) {
    return this.http.delete(`/api/${this.endpointName}/${parentId}/documents/${docId}`)
      .pipe(tap(() => this.documentDeleted(parentId, docId)));
  }

  // Upload one or more files to be linked to a Meeting.
  uploadDocuments(parentId: number, files: FileList, tab?: string) {
    const formData = new FormData();

    if (tab) {
      formData.append('tab', tab);
    }

    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size;
      formData.append(`file[${i}]`, files[i], files[i].name);
    }
    if (totalSize >= 220 * 1024 * 1204) {
      const err = new Error('This upload is too large. Please submit less than 200 MB.');
      throw throwError(() => err);
    }

    return this.http.post(
      `/api/${this.endpointName}/${parentId}/documents`,
      formData,
      { reportProgress: true, observe: 'events' }
    ).pipe(
      map((event: HttpEvent<{ data: Document[] }>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            return { percentage: Math.round(event.loaded / event.total * 100) };

          case HttpEventType.Response:
            this.documentsUpdated(parentId, event.body.data);
            return event.body;

          default:
            // Unhandled event type
            return null;
        }
      })
    );
  }

  protected abstract documentsUpdated(id: number, documents: Document[]): void
  protected abstract documentDeleted(parentId: number, docId: number): void

}
