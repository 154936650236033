import { BaseModel } from './baseModel';
import { Plan, plans } from 'app/models/types/plans';
import { authId } from 'app/common/helpers';

export type UserSettings = {
  sphere_id?: number;
  circle_id?: number;
}
export class User extends BaseModel {

  id = 0;
  hash = ''; // used for iCal link
  is_new = false;
  first_name = '';
  last_name = '';
  email = '';
  phone = '';
  linkedin = '';
  picture_url = '';
  account_id = '';
  private license_plan: string|Plan = 'pay-per-use';

  emails: string[] = [];

  // This is only ever queried upon page load. After that, synced by SettingsRepository.
  settings: UserSettings = {};

  // Meeting/Circle/Sphere pivot
  response: 0|1|2|3 = 0; // 1: accepted, 2: declined, 3: tentative
  last_viewed = 0;
  access_level: 0|1|2 = 1; // 0: viewer, 1: attendee, 2: writer
  label = ''; // Role or Function

  // Task pivot
  is_assignee = false;
  is_blocked = false;

  is_guest = false;
  guest_meeting_id = 0;

  constructor(object?: Partial<User>) {
    super();
    this.fill(object);
  }

  get licensePlan(): Plan {
    if (typeof this.license_plan === 'string') {
      return this.license_plan = plans.find(x => x.plan === this.license_plan) || plans[0];
    }
    else return this.license_plan;
  }

  // Return the User's full name, or if that is not available, the user part of the email address.
  get name(): string {
    return this.tryFullName || this.nameFromEmail;
  }
  // Return the User's full name, if possible.
  get tryFullName(): string {
    return (this.first_name + ' ' + this.last_name).trim();
  }
  get nameFromEmail(): string {
    // Get it from the email address. (Lookbehind regex is not supported in Safari.)
    const fullName = ' ' + this.email.substring(0, this.email.indexOf('@')).replace('.', ' ');
    return fullName.replace(/ [\w]/g, a => a.toLocaleUpperCase()).trim();
  }

  // Check whether this User viewed the Meeting within the past 30 seconds.
  get isViewing() {
    return this.last_viewed > Date.now() / 1000 - 30;
  }

  get isMember() {
    return this.access_level > 0;
  }

  get isMe() {
    return this.id === authId();
  }

  get responseClass() {
    switch (this.response) {
      case 1: return 'accepted';
      case 2: return 'declined';
      case 3: return 'tentative';
      default: return 'pending';
    }
  }

  get responseIcon() {
    switch (this.response) {
      case 1: return 'check';
      case 2: return 'clear';
      case 3: return '';
      default: return 'more_horiz';
    }
  }

  get responseTooltip() {
    switch (this.response) {
      case 1: return 'Invitation accepted';
      case 2: return 'Invitation declined';
      case 3: return 'Tentative';
      default: return 'No response yet';
    }
  }

}
