import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/en-gb';

@Pipe({
  name: 'dated',
  pure: true
})
export class DatedPipe implements PipeTransform {

  transform(value: string | number | Date | moment.Moment, short: boolean = false, timer: Date = null): any {
    if (!value) {
      return '';
    }
    if (!timer) {
      timer = new Date();
    }
    if (typeof value === 'number') {
      value *= 1000;
    }
    moment.locale('en-gb');
    const m = moment(value);

    const seconds: number = Math.floor((moment(timer).unix() - m.unix()));
    let interval: number;

    // If it's more than a day ago, return the date.
    if (seconds > 86400) {
      return m.format(short ? 'D MMM' : 'LL');
    }

    // If it's less than a day ago, show number of hours or minutes ago.
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + (short ? ' hours' : ' hours ago');
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + (short ? ' min' : ' minutes ago');
    }
    interval = Math.floor(seconds / 30);
    if (interval > 1) {
      return (short ? '1 min' : '1 minute ago');
    }
    // if (seconds > 5) {
    //   return seconds + ' seconds ago';
    // }
    return 'just now';
  }

}
