<section class="box">
  <label>{{ membersTitle }}</label>
  <mat-list dense>
    <mat-list-item *ngFor="let user of members" class="user-line">
      <span class="user-avatar">
        <app-user-avatar [user]="user"></app-user-avatar>
          <span
              *ngIf="user.response !== 1"
              class="response"
              [class]="user.responseClass"
              [matTooltip]="user.responseTooltip"
          ><mat-icon>{{ user.responseIcon }}</mat-icon
          ></span>
      </span>
      <div class="user-name">
        {{ user.name }}<span class="user-org" *ngIf="user.label">({{ user.label }})</span>
        <span>
          <mat-icon
              *ngIf="user.access_level === 2"
              class="status-icon mat-color-lightgrey"
              matTooltip="Co-editor"
          >gavel</mat-icon>
        </span>
      </div>
      <!-- contact options -->
      <div class="user-contact-icons" *ngIf="user.response === 1 || canWrite">
        <a href="tel:{{user.phone}}" *ngIf="user.phone">
          <mat-icon class="mat-color-lightgrey">phone</mat-icon>
        </a>
        <a [href]="user.linkedin" *ngIf="user.linkedin" class="linkedin" target="_blank" >in</a>
        <a href="mailto:{{user.email}}">
          <mat-icon class="mat-color-lightgrey">email</mat-icon>
        </a>
      </div>
      <span class="spacer"></span>
      <button *ngIf="canWrite"
          mat-icon-button
          class="mat-color-lightgrey"
          (click)="openPopUp(user)">
        <mat-icon>edit</mat-icon>
      </button>
    </mat-list-item>
    <mat-list-item *ngIf="canWrite" class="list-user-add">
      <flxion-user-select matLine
          label="Add a member"
          [skipUserIds]="skipUserIds"
          (onSelect)="addUser($event)"
      ></flxion-user-select>
    </mat-list-item>
  </mat-list>
</section>
<section class="box" *ngIf="isMember">
  <label>{{ visitorsTitle }}</label>
  <div *ngIf="!visitors.length">{{ visitorsHint }}</div>
  <mat-list dense>
    <mat-list-item *ngFor="let user of visitors" class="user-line">
      <span class="user-avatar">
        <app-user-avatar [user]="user"></app-user-avatar>
      </span>
      <div class="user-name">
        {{ user.name }}<span class="user-org" *ngIf="user.label">({{ user.label }})</span>
      </div>
      <!-- contact options -->
      <div class="user-contact-icons">
        <a href="tel:{{user.phone}}" *ngIf="user.phone">
          <mat-icon class="mat-color-lightgrey">phone</mat-icon>
        </a>
        <a [href]="user.linkedin" *ngIf="user.linkedin" class="linkedin" target="_blank" >in</a>
        <a href="mailto:{{user.email}}">
          <mat-icon class="mat-color-lightgrey">email</mat-icon>
        </a>
      </div>
      <span class="spacer"></span>
      <button *ngIf="canWrite"
          mat-icon-button
          class="mat-color-lightgrey"
          (click)="openPopUp(user)">
        <mat-icon>edit</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</section>
