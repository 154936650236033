export class BaseModel {

  constructor(object?: any) {}

  static parseObjects<T extends typeof BaseModel>(this: T, objects: any[]): InstanceType<T>[] {
    if (objects === null) {
      // This is important so that loader icons show correctly.
      return null;
    }
    const newList: InstanceType<T>[] = [];
    objects?.forEach(object => newList.push(new this(object) as InstanceType<T>));
    return newList;
  }

  fill(object: any) {
    if (object && typeof object === 'object') {
      // Copy over properties from passed objects.
      // If the property is an Array or other object, clone it.
      for (const key of Object.getOwnPropertyNames(this)) {
        if (object.hasOwnProperty(key)) {
          if (object[key] instanceof Array) {
            this[key] = [...object[key]];
            continue;
          }
          if (object[key] && typeof object[key] === 'object') {
            this[key] = typeof object[key].clone === 'function'
              ? object[key].clone()
              : {...object[key]};
            continue;
          }
          this[key] = object[key];
        }
      }
    }
  }

}
