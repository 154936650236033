import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';
import { init as sentryInit, instrumentAngularRouting } from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  sentryInit({
    dsn: "https://0f40d162af5e4b0bbafc5d170553198d@o916154.ingest.sentry.io/5857368",
    release: "flxion@" + ((window as any).SENTRY_RELEASE || "prod"),
    integrations: [
      new BrowserTracing({
        tracingOrigins: ["localhost", "https://app.flxion.com", "https://beta.flxion.com"],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    ignoreErrors: ['Non-Error exception captured'],

    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  console.info('Flxion started in Production mode.');
  enableProdMode();
} else {
  console.info('Flxion started in Development mode.');
}

platformBrowser().bootstrapModule(AppModule)
  .catch(err => console.error(err));
