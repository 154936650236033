import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { UserService } from './user.service';
import { Address } from 'app/models';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  get accountId() {
    return this.userService.user.account_id;
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
  ) { }

  getPlan() {
    return this.http.get<any>(`/api/accounts/${this.accountId}/plan`);
  }

  getBalance() {
    return this.http.get<any>(`/api/accounts/${this.accountId}/balance`);
  }

  subscribeToPlan(plan: string) {
    return this.http.post<any>(`/api/accounts/${this.accountId}/plan`, {plan});
  }

  placeOrder(plan: string) {
    return this.http.post<any>(`/api/accounts/${this.accountId}/order`, {plan});
  }

  getInvoices() {
    return this.http.get<any>(`/api/accounts/${this.accountId}/invoices`)
      .pipe(
        map(x => x.data)
      );
  }

  getAddress(withVatRate = false) {
    return this.http.get<any>(`/api/accounts/${this.accountId}/address` + (withVatRate ? '?with-vat-rate' : ''))
      .pipe(
        map(x => new Address(x.data))
      );
  }

  saveAddress(data: Address, getVatRate = false) {
    return this.http.put<any>(`/api/accounts/${this.accountId}/address` + (getVatRate ? '?get-vat-rate' : ''), data)
      .pipe(
        map(x => new Address(x.data))
      );
  }

  getDetailsFromVat(nr: string) {
    return this.http.get<any>(`/api/vat-details/${nr}`)
    .pipe(
        map(x => new Address(x.data))
    );
  }

}
